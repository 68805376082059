import React from 'react';
import LocalizedStrings from 'react-localization';
import ConfirmationModal from '../../../../common/components/ConfirmationModal';

const strings = new LocalizedStrings({
  en: {
    maxResultsAlmostReached: 'Maximum Results Reached',
    youHaveAlmostReachedTheMax: `You've reached the maximum number of results requests (5) for your session. You can still interact with your latest results request but will be unable to fetch new results. Please contact support@ballfitting.com for more details.`,
    backToResults: 'BACK TO RESULTS',
    purchaseNewSession: 'PURCHASE NEW SESSION',
  },
});

const MaxResultsReachedModal = ({ isopen, onConfirm, onPurchase }) => {
  return (
    <ConfirmationModal
      isOpened={isopen}
      content={strings.youHaveAlmostReachedTheMax}
      titleText={strings.maxResultsAlmostReached}
      buttonText={strings.backToResults}
      leftButtonText={strings.purchaseNewSession}
      onConfirm={onConfirm}
      onPurchase={onPurchase}
    />
  );
};

export default MaxResultsReachedModal;
