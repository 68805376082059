import React, { Component, Fragment } from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';
import FatClearIcon from './icons/FatClear';
import LocalizedStrings from 'react-localization';
import FocusTrap from 'focus-trap-react';

const strings = new LocalizedStrings({
  en: {
    close: 'Close',
  },
});

const KEYCODES = {
  ESCAPE: 27,
};

class Modal extends Component {
  constructor(props) {
    super(props);
    this.modalContentsNode = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpened && !prevProps.isOpened) {
      document.addEventListener('keydown', this.handleKeydown);
      document.addEventListener('mousedown', this.handleOutsideMouseClick);
    }

    if (!this.props.isOpened && prevProps.isOpened) {
      this.removeListeners();
    }
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  removeListeners() {
    document.removeEventListener('keydown', this.handleKeydown);
    document.removeEventListener('mousedown', this.handleOutsideMouseClick);
  }

  handleOutsideMouseClick = e => {
    if (!this.props.isOpened || !this.props.shouldCloseOnOutsideClick) {
      return;
    }
    const root = this.modalContentsNode;
    if (!root || root.contains(e.target) || (e.button && e.button !== 0)) {
      return;
    }
    this.props.onClose();
  };

  handleKeydown = e => {
    if (this.props.isOpened) {
      if (e.keyCode === KEYCODES.ESCAPE) {
        this.props.onClose();
      }
    }
  };

  render() {
    const { isOpened, children, title, className, onClose } = this.props;

    if (!isOpened) {
      return null;
    }

    return (
      <Portal>
        <Fragment>
          <FocusTrap>
            <div className={classNames(className, 'modal', { 'modal--visible': isOpened })}>
              <div className="modal__children" ref={modalContentsNode => (this.modalContentsNode = modalContentsNode)}>
                <div className="modal--title">
                  <h1>{title}</h1>
                  {onClose && (
                    <button
                      className="modal__close"
                      onClick={onClose}
                      type="button"
                      aria-label={`${strings.close} ${title}`}
                    >
                      <FatClearIcon fill="#616A70" />
                    </button>
                  )}
                </div>
                {children}
              </div>
            </div>
          </FocusTrap>
          <div className="modal__overlay" />
        </Fragment>
      </Portal>
    );
  }
}

Modal.defaultProps = {
  shouldCloseOnOutsideClick: true, // Add default prop
};

export default Modal;
