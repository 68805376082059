import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import SettingsModal from './SettingsModal';
import KebabMenu from '../common/components/KebabMenu';
import LocalizedStrings from 'react-localization';
import Logo from '../common/components/logo/Logo';
import SettingsIcon from '../common/components/icons/Settings';
import PowerIcon from '../common/components/icons/Power';
import UserIcon from '../common/components/icons/User';
import Header from '../common/components/Header';
import Sidebar from './Sidebar';
import Footer from '../common/components/Footer';
import TableHistory from '../common/components/icons/TableHistory';
import CoBrand from '../common/components/CoBrand';

const activeStyle = {
  borderBottom: '4px solid #5057FF',
};

let strings = new LocalizedStrings({
  en: {
    myFitting: 'My Fitting',
    library: 'Library',
    logout: 'Logout',
    settings: 'Settings',
    profile: 'Profile',
    myResults: 'My Results',
    showMenu: 'Show Menu',
    haveAnyFeedback: `Have any feedback or looking for help? Email us at {email}`,
    landingPage: 'Landing Page',
    faq: 'FAQ',
  },
});

const SETTINGS_MODAL = 'SETTINGS_MODAL';

class FitterLayout extends Component {
  state = {
    showKebabMenu: false,
    showMobileKebabMenu: false,
    showSideBar: false,
  };

  questionnaireIsActive = (match, location) => {
    return /^\/my-fitting/.test(location.pathname);
  };

  renderKebabMenu(isMobile = false) {
    const stateKey = isMobile ? 'showMobileKebabMenu' : 'showKebabMenu';
    const { loggedInUser } = this.props;
    return (
      <KebabMenu
        buttonAriaLabel={strings.showMenu}
        menuOpened={this.state[stateKey]}
        onDisengage={() => this.setState({ [stateKey]: false })}
        onClick={() => this.setState({ [stateKey]: true })}
      >
        <Link className="button--icon menu-item" to="/profile" aria-label={strings.profile}>
          <UserIcon fill="#505D6F" />
          <span>{strings.profile}</span>
        </Link>
        <Link className="button--icon menu-item" to="/profile/my-results" aria-label={strings.profile}>
          <TableHistory fill="#505D6F" />
          <span>{strings.myResults}</span>
        </Link>
        {loggedInUser.get('type') === 'admin' ||
          (loggedInUser.get('type') === 'fitter' && (
            <Link className="button--icon menu-item" to="/profile/settings" aria-label={strings.profile}>
              <SettingsIcon fill="#505D6F" />
              <span>{strings.settings}</span>
            </Link>
          ))}
        <Link className="logout-link button--icon menu-item" to="/logout" aria-label={strings.logout}>
          <PowerIcon fill="#505D6F" />
          <span>{strings.logout}</span>
        </Link>
      </KebabMenu>
    );
  }

  renderMobileKebabMenu() {
    return this.renderKebabMenu(true);
  }

  renderMobileNav() {
    const { loggedInUser } = this.props;

    return (
      <div className="mobile-nav">
        <div className="mobile-nav__flex-full">
          <Sidebar
            buttonAriaLabel={strings.showMenu}
            isOpen={this.state.showSideBar}
            activeStyle={activeStyle}
            onClose={() => this.setState({ showSideBar: false })}
            onOpen={() => this.setState({ showSideBar: true })}
            questionnaireIsActive={this.questionnaireIsActive}
          />
        </div>
        <div className="mobile-nav__logo">
          <Link to="/" aria-label={strings.landingPage}>
            <Logo fill="#5057FF" width={115} height={32} />
          </Link>
        </div>
        <div className="mobile-nav__flex-full">
          {loggedInUser.has('coBrandData') && (
            <CoBrand
              className="user-nav__co-brand"
              imageUrl={loggedInUser.getIn(['coBrandData', 'svg_src'])}
              name={loggedInUser.getIn(['coBrandData', 'name'])}
              height={32}
            />
          )}
          {this.renderMobileKebabMenu()}
        </div>
      </div>
    );
  }

  renderNav() {
    const { location, loggedInUser } = this.props;

    return (
      <Header
        coBrandImageUrl={loggedInUser.getIn(['coBrandData', 'svg_src'])}
        coBrandName={loggedInUser.getIn(['coBrandData', 'name'])}
        kebabMenu={this.renderKebabMenu()}
      >
        <div className="user-nav__right">
          <div className="user-nav__links">
            <NavLink
              className="user-nav__links--link"
              to={{
                pathname: location.state?.returnPath || '/my-fitting/get-started',
              }}
              activeStyle={activeStyle}
              isActive={this.questionnaireIsActive}
            >
              {strings.myFitting}
            </NavLink>
            <NavLink
              className="user-nav__links--link"
              to={{
                pathname: '/library',
                state: {
                  returnPath: location.pathname.startsWith('/my-fitting')
                    ? location.pathname
                    : location.state?.returnPath,
                },
              }}
              activeStyle={activeStyle}
            >
              {strings.library}
            </NavLink>
            <NavLink
              className="user-nav__links--link"
              to={{
                pathname: '/faq',
                state: {
                  returnPath: location.pathname.startsWith('/my-fitting')
                    ? location.pathname
                    : location.state?.returnPath,
                },
              }}
              activeStyle={activeStyle}
            >
              {strings.faq}
            </NavLink>
          </div>
        </div>
      </Header>
    );
  }

  render() {
    return (
      <div className="fitter-layout">
        <SettingsModal
          isOpened={this.state.displayedModal === SETTINGS_MODAL}
          onClose={() => this.setState({ displayedModal: null })}
        />
        {this.renderNav()}
        {this.renderMobileNav()}
        <div className="fitter-layout__children">{this.props.children}</div>
        <Footer fitter />
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
  })),
  withRouter
)(FitterLayout);
