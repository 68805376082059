import client from '../../client';
import hash from 'object-hash';

import {
  CALCULATE_FLIGHTS,
  SET_QUESTIONNAIRE_VALUES,
  SET_RECIPIENTS,
  RESET_QUESTIONNAIRE,
  FETCH_BRANDS,
  FETCH_MODELS,
  FETCH_YEARS,
  RESET_MODELS,
  RESET_YEARS,
  CALCULATE_DRIVER_LAUNCH_CONDITIONS,
  CALCULATE_IRON_LAUNCH_CONDITIONS,
  CALCULATE_RELATIVE_RESULTS,
  RESET_RELATIVE_RESULTS,
  FETCH_PRICES,
  FETCH_RESULTS,
  SEND_RESULTS,
  RESET_SEND_RESULTS,
  FETCH_STATIC_RESULTS,
  LOOK_UP_TEMPERATURE_AND_ALTITUDE,
  CALCULATE_DRIVER_HEIGHT_PREFERENCE,
  CALCULATE_IRON_STOPPING_PREFERENCE,
  RESET_CALCULATED_IRON_STOP,
  RESET_CALCULATED_DRIVER_HEIGHT,
  ACCEPT_DISCLAIMER,
  IRON_LAUNCH_CONDITIONS_KNOWN,
  DRIVER_LAUNCH_CONDITIONS_KNOWN,
  GET_RESULT_TEASER,
} from './questionnaire-action-constants';

export function setQuestionnaireValues(values) {
  return {
    type: SET_QUESTIONNAIRE_VALUES,
    values,
  };
}

export function resetQuestionnaire() {
  return {
    type: RESET_QUESTIONNAIRE,
  };
}

export function resetModels() {
  return {
    type: RESET_MODELS,
  };
}

export function resetYears() {
  return {
    type: RESET_YEARS,
  };
}

export function fetchBrands() {
  return {
    type: FETCH_BRANDS,
    promise: client.get('fitting/brands'),
  };
}

export function fetchModels(brand) {
  return {
    type: FETCH_MODELS,
    promise: client.get(`fitting/models?brand=${brand}`),
  };
}

export function fetchYears(brand, model) {
  return {
    type: FETCH_YEARS,
    promise: client.get(`fitting/years?brand=${brand}&model=${model}`),
  };
}

export function fetchPrices() {
  return {
    type: FETCH_PRICES,
    promise: client.get(`fitting/prices`),
  };
}

export function fetchResults(values) {
  return {
    type: FETCH_RESULTS,
    promise: client.post(`fitting/results`, values),
    valuesHash: hash(values),
  };
}

export function calculateFlights(playerData, topBalls) {
  return {
    type: CALCULATE_FLIGHTS,
    promise: client.post(`fitting/flights`, { playerData, topBalls }),
  };
}

export function calculateDriverLaunchConditions(values) {
  return {
    type: CALCULATE_DRIVER_LAUNCH_CONDITIONS,
    promise: client.get(`fitting/calc-driver-launch-conditions`, values),
  };
}

export function calculateDriverHeightPreference(values) {
  return {
    type: CALCULATE_DRIVER_HEIGHT_PREFERENCE,
    promise: client.post(`fitting/calc_driver_height_pref`, values),
  };
}

export function resetCalculatedDriverHeight() {
  return {
    type: RESET_CALCULATED_DRIVER_HEIGHT,
  };
}

export function calculateIronLaunchConditions(values) {
  return {
    type: CALCULATE_IRON_LAUNCH_CONDITIONS,
    promise: client.get(`fitting/calc-iron-launch-conditions`, values),
  };
}

export function calculateIronStoppingPreference(values) {
  return {
    type: CALCULATE_IRON_STOPPING_PREFERENCE,
    promise: client.post(`fitting/calc_iron_stop_pref`, values),
  };
}

export function resetCalculatedIronStoppingPreference() {
  return {
    type: RESET_CALCULATED_IRON_STOP,
  };
}

export function calculateRelativeResults(values) {
  return {
    type: CALCULATE_RELATIVE_RESULTS,
    promise: client.post(`fitting/calc-relative-results`, values),
  };
}

export function resetRelativeResults() {
  return {
    type: RESET_RELATIVE_RESULTS,
  };
}

export function setRecipients(recipients) {
  return {
    type: SET_RECIPIENTS,
    recipients,
  };
}

export function sendResults(recipients, values, favoriteBall, fitterComments, promoCode) {
  return {
    type: SEND_RESULTS,
    promise: client.post(`fitting/send-results`, {
      recipients,
      values,
      favorite_ball: favoriteBall,
      fitter_comments: fitterComments,
      promoCode: promoCode,
    }),
  };
}

export function sendSelfResults() {
  return {
    type: SEND_RESULTS,
    promise: client.post(`fitting/send-self-results`),
  };
}

export function resetSendResults() {
  return {
    type: RESET_SEND_RESULTS,
  };
}

export function fetchStaticResults(resultsId) {
  return {
    type: FETCH_STATIC_RESULTS,
    promise: client.get(`fitting/results/${resultsId}`),
  };
}

export function lookUpTemperatureAndAltitude(zipcode) {
  return {
    type: LOOK_UP_TEMPERATURE_AND_ALTITUDE,
    promise: client.get(`fitting/temperature-and-altitude`, { zipcode }),
  };
}

export function fetchLatestResults() {
  return {
    type: FETCH_RESULTS,
    promise: client.get(`fitting/latest-results`),
  };
}

export function acceptDisclaimer() {
  return {
    type: ACCEPT_DISCLAIMER,
  };
}

export function ironLaunchConditionsKnown(known) {
  return {
    type: IRON_LAUNCH_CONDITIONS_KNOWN,
    known,
  };
}

export function driverLaunchConditionsKnown(known) {
  return {
    type: DRIVER_LAUNCH_CONDITIONS_KNOWN,
    known,
  };
}

export function getResultTeaser(player_data) {
  return {
    type: GET_RESULT_TEASER,
    promise: client.post(`fitting/teaser`, { player_data }),
  };
}
