import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import LogoWhite from './logo/LogoWhite';

const strings = new LocalizedStrings({
  en: {
    fitterContact: 'Have any feedback or looking for help? Email us at {0}',
    customContact: 'Otherwise, send us an email at {0} and we’ll get back to you within 48 hours.',
    copyright:
      '{0} Copyright {1} PING Inc. All rights reserved. Ballnamic is a trademark of Karsten Manufacturing Corporation in the U.S. and other countries. All third-party trademarks are the property of their respective owners, and use of such trademarks does not imply any affiliation with or endorsement by them.',
    terms: 'Terms of Use',
    privacy: 'Privacy Policy',
    landingPage: 'Landing Page',
    affiliate: 'Affiliate Program',
    privSettings: 'Privacy Settings',
    doNotSell: 'Do Not Sell or Share My Personal Information',
  },
});

class Footer extends Component {
  clickPreferences(){
    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }
  clickDoNotSell(){
    window.Osano.cm.showDoNotSell();
  }
  render() {
    const { fitter } = this.props;

    return (
      <footer className="landing-page--footer">
        <div className="container landing-page">
          <div className="footer__inner">
            <div className="footer--top">
              <Link to="/" className="logo" aria-label={strings.landingPage}>
                <LogoWhite width={172} height={48} />
              </Link>
              <div className="custom-contact">
                <p>
                  Visit our <a href="/faq">FAQ Page</a> for our most commonly asked questions.
                  <br />
                  {strings.formatString(
                    fitter ? strings.fitterContact : strings.customContact,
                    <a href="mailto:support@ballfitting.com">support@ballfitting.com</a>
                  )}
                </p>
              </div>
            </div>
            <div className="footer--bottom">
              <p className="copyright">
                {strings.formatString(strings.copyright, <span>&copy;</span>, new Date().getFullYear())}
              </p>
              <div className="footer--links">
                <Link to="/terms-of-use">{strings.terms}</Link>
                <span>|</span>
                <Link to="/privacy">{strings.privacy}</Link>
                {/* <span>|</span>
                <Link to="/affiliate">{strings.affiliate}</Link> */}
                <span>|</span>
                <Link to="/affiliate">{strings.affiliate}</Link>
                <span>|</span>               
                <Link to="#" onClick={this.clickPreferences}>{strings.privSettings}</Link>
                <span>|</span>
                <Link to="#" onClick={this.clickDoNotSell}>{strings.doNotSell}</Link>
                
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
/*
Below are failed attempts to implement Osano drawer opening from their documentation
keeping them here in case needed again
<Link to="/" onclick={window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}>{strings.privSettings}</Link>
<span>|</span>
<a href="#" onClick={this.clickPreferences}>{strings.privSettings}</a>
<span>|</span>
<a href="/" onClick={window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}>Cookie Preferences</a>
<span>|</span>
*/
export default Footer;
