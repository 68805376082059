import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import PaymentInfo from './PaymentInfo';
import BillingLocationForm from './BillingLocationForm';
import { getSalesTax, resetSalesTax, resetResultsPayment } from '../../../payment/payment-actions';
import LocalizedStrings from 'react-localization';
import Button from '../Button';
import getTotal from './get-total';
import ResultTeaser from './ResultTeaser';

const strings = new LocalizedStrings({
  en: {
    invalidBillingLocation: 'Invalid billing location',
    thereWasAProblem: 'There was a problem submitting your billing information',
    couldNotCompleteTransaction: `We could not complete your transaction at this time`,
    paymentInformation: 'Payment Information',
    weAreSorry: `We're Sorry`,
    elevateYourGame: 'Elevate your game',
    paymentHelperTextOne: `One payment of $39`,
    paymentHelperTextTwo: `Generate up to 5 unique ball fittings (1-Year access)`,
    paymentHelperTextThree: `Results permanently stored in profile`,
    paymentHelperTextFour: `U.S. only`,
  },
});

const TEASER_STEP = 'teaser';
const PAYMENT_STEP = 'payment';

class PaymentForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    token: PropTypes.string,
    price: PropTypes.object, // Dinero object
    isOpened: PropTypes.bool,
    submittingPayment: PropTypes.bool,
    hasPaymentError: PropTypes.bool,
  };

  state = {
    submittedBillingLocation: null,
    currentStep: TEASER_STEP,
  };

  componentDidUpdate(prevProps) {
    const { dispatch, promoCode, getSalesTaxRequestState, price } = this.props;

    if (!prevProps.isOpened && this.props.isOpened) {
      dispatch(resetSalesTax());
      this.setState({ submittedBillingLocation: null });
    }

    if (prevProps.promoCode !== promoCode) {
      if (promoCode && promoCode.get('valid')) {
        dispatch(
          getSalesTax({ ...this.state.submittedBillingLocation, price: promoCode.getIn(['order', 'total_amount']) })
        );
      } else {
        dispatch(getSalesTax({ ...this.state.submittedBillingLocation, price: price.getAmount() }));
      }
    }

    if (
      prevProps.getSalesTaxRequestState.get('loading') &&
      !getSalesTaxRequestState.get('loading') &&
      getSalesTaxRequestState.get('loaded')
    ) {
      const salesTaxRequestState = getSalesTaxRequestState.getIn(['meta', 'values']);
      this.setState({
        submittedBillingLocation: {
          city: salesTaxRequestState.get('city'),
          state: salesTaxRequestState.get('state'),
          postalCode: salesTaxRequestState.get('postalCode'),
          country: salesTaxRequestState.get('country'),
        },
      });
    }
  }

  getSalesTax = values => {
    const { dispatch, price, promoCode } = this.props;
    return dispatch(
      getSalesTax({
        ...values,
        price: promoCode && promoCode.get('valid') ? promoCode.getIn(['order', 'total_amount']) : price.getAmount(),
      })
    );
  };

  getSalesTaxError = () => {
    const { getSalesTaxRequestState } = this.props;

    if (getSalesTaxRequestState.get('error')) {
      if (getSalesTaxRequestState.getIn(['error', 'statusCode']) === 422) {
        return strings.invalidBillingLocation;
      } else {
        return strings.thereWasAProblem;
      }
    }

    return null;
  };

  submitPayment = nonce => {
    const { onSubmit, price, totalTax, promoCode } = this.props;

    return onSubmit(nonce, {
      ...this.state.submittedBillingLocation,
      expectedPrice: getTotal(price, totalTax, promoCode).getAmount(),
      promoCode: promoCode && promoCode.get('valid') ? promoCode.get('code') : null,
    });
  };

  renderPaymentContent() {
    const { onCancel, totalTax, token, price, hasPaymentError, dispatch } = this.props;

    return (
      <>
        {!hasPaymentError && (
          <div className="helper-text">
            <ul>
              <li>{strings.paymentHelperTextOne}</li>
              <li>{strings.paymentHelperTextTwo}</li>
              <li>{strings.paymentHelperTextThree}</li>
              <li>{strings.paymentHelperTextFour}</li>
            </ul>
          </div>
        )}
        {!this.state.submittedBillingLocation ? (
          <BillingLocationForm onSubmit={this.getSalesTax} error={this.getSalesTaxError()} />
        ) : (
          <>
            {hasPaymentError ? (
              <>
                <h3>{strings.couldNotCompleteTransaction}</h3>
                <div className="payment-form--button__wrapper">
                  <Button
                    type="button"
                    onClick={() => {
                      dispatch(resetResultsPayment()); // reset error
                      onCancel();
                    }}
                  >
                    OK
                  </Button>
                </div>
              </>
            ) : (
              <PaymentInfo
                totalTax={totalTax}
                token={token}
                price={price}
                onSubmit={this.submitPayment}
                onCancel={onCancel}
              />
            )}
          </>
        )}
      </>
    );
  }

  render() {
    const { isOpened, onCancel, hasPaymentError, resultTeaser } = this.props;

    let title = strings.elevateYourGame;
    if (this.state.currentStep === PAYMENT_STEP) {
      title = hasPaymentError ? strings.weAreSorry : strings.paymentInformation;
    }

    return (
      <Modal className="payment-modal" isOpened={isOpened} onClose={onCancel} title={title}>
        {this.state.currentStep === TEASER_STEP && (
          <ResultTeaser resultTeaser={resultTeaser} onClickNext={() => this.setState({ currentStep: PAYMENT_STEP })} />
        )}
        {this.state.currentStep === PAYMENT_STEP && this.renderPaymentContent()}
      </Modal>
    );
  }
}

export default connect(state => ({
  totalTax: state.payment.totalTax,
  getSalesTaxRequestState: state.payment.getSalesTax,
  promoCode: state.payment.promoCode,
}))(PaymentForm);
