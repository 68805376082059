import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../common/components/Modal';
import ValidationError from '../common/forms/ValidationError';
import {
  getBraintreeToken,
  getSalesTax,
  resetSalesTax,
  getPrices,
  submitResultsPayment,
} from '../payment/payment-actions';
import config from '../config';
import LocalizedStrings from 'react-localization';
import getTotal from '../common/components/PaymentForm/get-total';
import Dinero from 'dinero.js';
import dropin from 'braintree-web-drop-in';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import BillingLocationForm from '../common/components/PaymentForm/BillingLocationForm';
import * as Yup from 'yup';
// import { getPromoCode } from '../payment/payment-actions';
import PromoCodeForm from '../common/components/PaymentForm/PromoCodeForm';

const stateCodesMap = {
  "Alabama"         : "AL",
  "Alaska"          : "AK",
  "Arizona"         : "AZ",
  "Arkansas"        : "AR",
  "California"      : "CA",
  "Colorado"        : "CO",
  "Connecticut"     : "CT",
  "Delaware"        : "DE",
  "Florida"         : "FL",
  "Georgia"         : "GA",
  "Hawaii"          : "HI",
  "Idaho"           : "ID",
  "Illinois"        : "IL",
  "Indiana"         : "IN",
  "Iowa"            : "IA",
  "Kansas"          : "KS",
  "Kentucky"        : "KY",
  "Louisiana"       : "LA",
  "Maine"           : "ME",
  "Maryland"        : "MD",
  "Massachusetts"   : "MA",
  "Michigan"        : "MI",
  "Minnesota"       : "MN",
  "Mississippi"     : "MS",
  "Missouri"        : "MO",
  "Montana"         : "MT",
  "Nebraska"        : "NE",
  "Nevada"          : "NV",
  "New Hampshire"   : "NH",
  "New Jersey"      : "NJ",
  "New Mexico"      : "NM",
  "New York"        : "NY",
  "North Carolina"  : "NC",
  "North Dakota"    : "ND",
  "Ohio"            : "OH",
  "Oklahoma"        : "OK",
  "Oregon"          : "OR",
  "Pennsylvania"    : "PA",
  "Rhode Island"    : "RI",
  "South Carolina"  : "SC",
  "South Dakota"    : "SD",
  "Tennessee"       : "TN",
  "Texas"           : "TX",
  "Utah"            : "UT",
  "Vermont"         : "VT",
  "Virginia"        : "VA",
  "Washington"      : "WA",
  "West Virginia"   : "WV",
  "Wisconsin"       : "WI",
  "Wyoming"         : "WY"
};
const strings = new LocalizedStrings({
  en: {
    PaymentInformation: 'Payment Information',
    BallnamicGiftVoucher: 'Ballnamic Gift Voucher',
    Email: 'Email *',
    ConfirmEmail: 'Confirm Email *',
    buttonNext: 'Next',
    AnErrorOccurredPaymentForm: 'An error occurred with the payment form.',
    InvalidEmailFormat: 'Invalid email format',
    EmailsMustMatch: 'Emails must match',
    InvalidConfirmEmailFormat: 'Invalid Confirm email format',
    EmailIsRequired: 'Email is required',
    ConfirmEmailIsRequired: 'Confirm Email is required',
    price: 'Amount',
    salesTax: 'Sales Tax',
    updating: 'Updating',
    invoice: 'Invoice',
    total: 'Total',
    PleaseEnterEmailAddress: 'Please enter the email address that you would like the voucher sent to:',
    paymentHelperTextOne: `One payment of $39`,
    paymentHelperTextTwo: `Generate up to 5 unique ball fittings (1-Year access)`,
    paymentHelperTextThree: `Results permanently stored in profile`,
    paymentHelperTextFour: `U.S. only`,
    EmailThatWillReceiveVoucher: 'Email that will receive the voucher:',
    paymentCantBeProcessed: `Payment can't be processed at this time. Please try again later.`,
    noPaymentMethodSelected: 'Please selected payment method!',
    purchaseVoucherSuccessful: 'Your purchase has been successfully completed. Please check your inbox in the next few minutes for redemption instructions.',
    purchaseVoucherError: 'An error occurred while purchasing Voucher! Please try again.',
    PurchaseGift: 'Purchase Gift',
  },
});

const defaultPrice = Dinero({ amount: 3900, currency: 'USD' }); // $39

const VoucherForm = ({ isOpened, onCancel }) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(null);
  const [titleModal, setTitleModal] = useState(strings.BallnamicGiftVoucher);
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [confirmEmailError, setConfirmEmailError] = useState('');
  const [allowNext, setAllowNext] = useState(false);
  const [braintreeError, setBraintreeError] = useState(null);
  const [instance, setInstance] = useState(null);
  const [formLoaded, setFormLoaded] = useState(false);
  const [submittingPayment, setSubmittingPayment] = useState(false);
  const braintreeToken = useSelector(state => state.payment.braintreeToken);
  const totalTax = useSelector(state => state.payment.totalTax);
  const prices = useSelector(state => state.payment.prices);
  const price = prices ? Dinero({ amount: prices.getIn(['PLAYER_RESULTS', 'USD']), currency: 'USD' }) : defaultPrice;
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  // const [setLocationErrors] = useState({ city: '', state: '', postalCode: '' });
  // Add state for promo code details
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState('');
  const [promoDiscountAmount, setPromoDiscountAmount] = useState(0); // Initialize with 0
  const [locationFormError, setLocationFormError] = useState('');
  // const [isTornDown, setIsTornDown] = useState(false);


 
  useEffect(() => {
    dispatch(getPrices());
  }, [dispatch]);

  useEffect(() => {
    if (isOpened) {
      setCurrentStep('FORM_STEP');
    }
  }, [isOpened]);

  useEffect(() => {
    if (isOpened) {
      dispatch(resetSalesTax());
    }
  }, [isOpened, dispatch]);

  // useEffect(() => {
  //   if (currentStep === 'FORM_STEP' && isOpened) {
  //     if (price && !totalTax) {
  //       dispatch(getSalesTax(paymentLocation));
  //     }
  //   }
  // }, [dispatch, price, totalTax, currentStep, isOpened, paymentLocation]);

  const calculateTotal = useCallback(() => {
    if (price && totalTax) {
      let totalPrice = getTotal(price, totalTax);
      if (promoCodeApplied) {
        setPromoCodeApplied(true);
        totalPrice = totalPrice.subtract(Dinero({ amount: promoDiscountAmount, currency: 'USD' }));
      }
      return totalPrice;
    }
  }, [price, totalTax, promoCodeApplied, promoDiscountAmount]);


  const getApplePayRequestObject = useCallback(() => {
    const total = calculateTotal();
    let displayTotal = '0.00';

    if (total) {
      displayTotal = total.toFormat('0.00');
    }

    return {
      total: {
        label: 'Ball Fitting Fee',
        amount: displayTotal,
      },
      countryCode: 'US',
      currencyCode: 'USD',
    };
  }, [calculateTotal]);

  useEffect(() => {
    if (currentStep === 'FORM_STEP') {
      dispatch(getBraintreeToken());
    }
  }, [dispatch, currentStep]);
  
  
  const createPaymentForm = useCallback(() => {
    setBraintreeError(null);
    const container = document.querySelector('#braintree-container');
    // Ensure the container is empty before creating a new instance
    if (container) {
        container.innerHTML = '';
    }

    const paypalConfig = config.payPalEnabled && { paypal: { flow: 'vault' } };
    const applePayConfig = config.applePayEnabled && {
        applePay: {
            displayName: 'Ping Ballnamic',
            paymentRequest: getApplePayRequestObject(),
        },
    };

    dropin.create({
        authorization: braintreeToken,
        selector: '#braintree-container',
        paymentOptionPriority: ['card', 'paypal', 'applePay'],
        ...paypalConfig,
        ...applePayConfig,
        card: {
            overrides: {
                styles: {
                    input: {
                        padding: '0 8px',
                    },
                },
            },
        },
    }, (err, instance) => {
        if (instance) {
            setInstance(instance);
            setFormLoaded(true);
        } else if (err) {
            // handle error
            setBraintreeError(strings.paymentCantBeProcessed);
            console.error(err || 'Payment form instance could not be initialized');
        }
    });
}, [braintreeToken, getApplePayRequestObject]);
  
  // useEffect(() => {
  //   // Ensuring the container is empty and ready
  //   const container = document.querySelector('#braintree-container');
  //   if (container) container.innerHTML = '';
    
  //   // Function to create Braintree instance
  //   const createInstance = () => {
  //       if (!braintreeToken || instance) return;

  //       // Braintree creation logic here
  //   };

  //   createInstance();

  //   // Cleanup function to teardown instance when component unmounts or token changes
  //   return () => {
  //       if (instance) {
  //           instance.teardown(() => {
  //               console.log('Drop-in UI has been torn down');
  //               setInstance(null); // Ensure the instance is set to null after teardown
  //           });
  //       }
  //   };
  // }, [braintreeToken, instance]); // React to changes in braintreeToken or instance

// Use isTornDown to conditionally trigger re-initialization
// useEffect(() => {
//   if (isTornDown) {
//       // Call createPaymentForm or other logic to re-initialize
//       setIsTornDown(false); // Reset state after re-initialization logic is triggered
//   }
// }, [isTornDown, createPaymentForm]);

  useEffect(() => {
    // This effect runs every time the modal is opened or the current step changes
    if (isOpened) {
      if (currentStep === 'SUMMARY_STEP') {
        console.log("Resetting promo code state for SUMMARY_STEP");
        // Explicitly reset promo code related states every time we enter SUMMARY_STEP
        setPromoCode('');
        setPromoCodeApplied(false);
        setPromoDiscountAmount(0);
        setPromoCodeError('');
        setBraintreeError(null);
      }
    }
  }, [currentStep, isOpened]); // Added isOpened to dependencies to catch modal open events
  
  
  // useEffect(() => {
  //   // Reset location form errors when opening the location step
  //   if (currentStep === 'LOCATION_STEP') {
  //     setLocationErrors({ city: '', state: '', postalCode: '' });
  //   }
  // }, [currentStep, setLocationErrors]);

  useEffect(() => {
    // Example of forcing re-calculation or re-render
    calculateTotal();
  }, [promoCodeApplied, promoDiscountAmount, totalTax, price, calculateTotal]); // Ensure all dependencies are listed
  

  useEffect(() => {
    if (braintreeToken && isOpened && currentStep === 'SUMMARY_STEP') {
      const container = document.querySelector('#braintree-container');
      if (container && container.innerHTML === '') {
        createPaymentForm();
      }
    }
  }, [braintreeToken, createPaymentForm, isOpened, currentStep]);
//   useEffect(() => {
//     // Guard clause to exit early if conditions aren't met
//     if (!isOpened || !braintreeToken) return;

//     const container = document.querySelector('#braintree-container');
//     if (!container) {
//         console.error('Braintree container not found');
//         return;
//     }

//     // Clear existing content in the container
//     container.innerHTML = '';

//     dropin.create({
//         authorization: braintreeToken,
//         selector: '#braintree-container',
//         // Additional configurations here...
//     }, (err, instance) => {
//         if (err) {
//             console.error('Braintree error:', err);
//             setBraintreeError('Payment form initialization failed.');
//             return;
//         }
//         setInstance(instance);
//         setFormLoaded(true);
//     });

//     // Teardown the instance when the component unmounts or conditions change
//     return () => {
//         if (instance) {
//             instance.teardown(() => {
//                 console.log('Braintree teardown complete.');
//             });
//         }
//     };
// }, [isOpened, braintreeToken]);

  
  // useEffect(() => {
  //   if (!isOpened || !braintreeToken) return;

  //   const container = document.querySelector('#braintree-container');
  //   // Ensure the container is empty
  //   container.innerHTML = '';

  //   // Initialize Braintree Drop-in
  //   const initializeBraintree = async () => {
  //       try {
  //           const instance = await dropin.create({
  //               authorization: braintreeToken,
  //               selector: '#braintree-container',
  //               // Additional configurations...
  //           });
  //           setInstance(instance);
//         } catch (err) {
//             console.error('Braintree initialization error:', err);
//             setBraintreeError('Failed to initialize payment form.');
//         }
//     };

//     initializeBraintree();

//     // Define the cleanup function
//     return () => {
//         if (instance) {
//             instance.teardown(() => {
//                 console.log('Braintree instance has been torn down');
//                 // Cleanup actions if necessary
//             });
//         }
//     };
// }, [isOpened, braintreeToken]); // Depend on isOpened and braintreeToken to control effect execution


  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setEmailError(strings.InvalidEmailFormat);
      setAllowNext(false);
    } else if (confirmEmail && event.target.value !== confirmEmail) {
      setEmailError(strings.EmailsMustMatch);
      setAllowNext(false);
    } else {
      setEmailError('');
      setConfirmEmailError('');
      setAllowNext(true);
    }
  };

  const handleConfirmEmailChange = event => {
    setConfirmEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setConfirmEmailError(strings.InvalidConfirmEmailFormat);
      setAllowNext(false);
    } else if (email !== event.target.value) {
      setConfirmEmailError(strings.EmailsMustMatch);
      setAllowNext(false);
    } else {
      setEmailError('');
      setConfirmEmailError('');
      setAllowNext(true);
    }
  };

const locationValidationSchema = Yup.object().shape({
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string().trim().required("Postal code is required"),
    // Assuming country is always 'US' and not a user input in this scenario
  });
    
  const validateLocationForm = async (city, state, postalCode) => {
    try {
      // Validate form values against the schema
      await locationValidationSchema.validate({ city, state, postalCode }, { abortEarly: false });
      // If validation passes, return true or the validated data
      return true;
    } catch (validationError) {
      // If validation fails, process and return the validation errors
      const errors = {};
      validationError.inner.forEach(error => {
        if (!errors[error.path]) {
          errors[error.path] = error.message;
        }
      });

      return { errors };
    }
  };

  const handleLocationFormSubmit = async ({ city, state, postalCode }) => {
    // Validate location form fields
    window.analytics.identify({
      postalCode: postalCode,
      state: stateCodesMap[state],
      city: city,
      country: 'US',
    });
    window.analytics.track("Checkout Step Completed", {
      city: city,
      state: stateCodesMap[state],
      postal_code: postalCode,
      country: 'US',
      fitting_id: null
    });
    window.gtag('event', 'Checkout Step Completed', {
      city: city,
      state: stateCodesMap[state],
      postal_code: postalCode,
      country: 'US',
      fitting_id: null
    });
    window.analytics.track("Checkout Step Viewed", {
      checkout_step: 3,
      fitting_id: null
    });
    window.gtag('event', 'Checkout Step Viewed', {
      checkout_step: 3,
      fitting_id: null
    });
    setLocationFormError('');
    const validationResult = await validateLocationForm(city, state, postalCode);
    if (validationResult === true) {
      try {
        // Define additional parameters
        const country = "US"; // Static value for country
        const priceValue = 3900; // Static value for price in cents (or however your API expects it)

        // Update component state with user-provided location details
        setCity(city);
        setState(state);
        setPostalCode(postalCode);
        // Dispatch action to fetch sales tax based on user-entered location
        // Include country and priceValue in the call
        const actionResponse = await dispatch(getSalesTax({ city, state, postalCode, country, price: priceValue }));
        // console.log(actionResponse);
        if (actionResponse.error || actionResponse.response.status === 422) {
        // If there's an error, prevent moving to the next step and show the error
          if (actionResponse.payload && actionResponse.response.status === 422) {
            // Set a specific error message for invalid input
            setLocationFormError('The provided billing location information is invalid. Please check your inputs and try again.');
          } else {
            // Handle other errors more generally
            setLocationFormError('Failed to calculate sales tax, please try again.');
          }
        }
         else {
          setCurrentStep('SUMMARY_STEP'); // Proceed to payment step only if there's no error
        }
      } catch (error) {
        console.error('Failed to calculate sales tax', error);
        setLocationFormError('Failed to calculate sales tax, please try again.'); // General error message
      }
    }
  };

  const fetchNewSalesTax = async (price) => {
    try {
      const response = await dispatch(getSalesTax({ city, state, postalCode, country: 'US', price }));
      if (response.error) {
        console.error('Failed to fetch new sales tax', response.error);
        return;
      } 
      return response.payload;
    } catch (error) {
      console.error('Failed to fetch new sales tax', error);
    }
  };
  const handlePromoCodeSubmit = (code, discountAmount) => {
    console.log("Promo code applied: ", code);
    setPromoCode(code);
    setPromoCodeError('');
    setPromoDiscountAmount(discountAmount);
    setPromoCodeApplied(true);
    const newPrice = price.subtract(Dinero({ amount: discountAmount, currency: 'USD' }));
    fetchNewSalesTax(newPrice.getAmount());
  };
  const handleFormSubmit = () => {
    if (!email) {
      setEmailError(strings.EmailIsRequired);
    }
    if (!confirmEmail) {
      setConfirmEmailError(strings.ConfirmEmailIsRequired);
    }

    if(currentStep === 'FORM_STEP'){
      if (email && confirmEmail && allowNext) {
        window.analytics.track("Checkout Step Completed", {
          recipient_email: email,
          step: 'fitting_voucher_1',
        });
        window.gtag('event', 'Checkout Step Completed', {
          recipient_email: email,
          step: 'fitting_voucher_1',
        });
        setCurrentStep('LOCATION_STEP');
      }
    }

  };

  const handleClose = () => {
    setCurrentStep(null);
    setTitleModal(strings.BallnamicGiftVoucher);
    setEmail('');
    setConfirmEmail('');
    setEmailError('');
    setConfirmEmailError('');
    setAllowNext(false);
    onCancel();
    setSubmittingPayment(false);
    const container = document.querySelector('#braintree-container');
    if (container) {
      container.innerHTML = '';
    }
  };

  const handleSubmitPayment = (nonce, payload) => {
    dispatch(submitResultsPayment(nonce, payload)).then(response => {
      setSubmittingPayment(false);
      if (response.response.status === 200) {
        const realTotal = Dinero({ amount: payload.expectedPrice, currency: 'USD' });
        const subTotalPost = realTotal.subtract(totalTax).toFormat('0.00');
        // const discountPost = null;
        // let discount = Dinero.subtract(price, Dinero.subtract(payload.expectedPrice, totalTax)).toFormat('0.00') || null;
        // console.log(discount);
        // console.log(discount.toFormat('0.00'));
        // console.log("Voucher Purchased Successfuly!");
        // console.log(price.toFormat('0.00'));
        // console.log(totalTax.toFormat('0.00'));
        // console.log(prices);
        // console.log(payload);
        // console.log(typeof prices);
        const totalPost = Dinero({ amount: payload.expectedPrice, currency: 'USD' });
        let discountPost = price.subtract(totalPost.subtract(totalTax)).toFormat('0.00') || null;
        // let subTotalPost = '9.00';
        window.analytics.track("Order Completed", {
          coupon: payload.promoCode || null,
          discount: discountPost || null,
          fitting_id: null,
          order_id: null,
          products:
          {
            brand: 'Ballnamic',
            name: 'Ballnamic Gift Voucher',
            quantity: 1,
            price: '39.00',
            discount_amount: discountPost || null,
            coupon: payload.promoCode || null,
          },
          recipient_email: email || null,
          subtotal: subTotalPost || null,
          tax: totalTax.toFormat('0.00') || null,
          total: totalPost.toFormat('0.00') || null,
        });
        toast(strings.purchaseVoucherSuccessful, { type: 'success', position: toast.POSITION.TOP_CENTER });
        handleClose();
        return <Redirect to={'/gift'} />;
      }
      toast(strings.purchaseVoucherError, { type: 'error', position: toast.POSITION.BOTTOM_CENTER });
      handleClose();
    });
  };

  const submitPayment = nonce => {
    const paymentLocation = {
      city,
      state,
      postalCode,
      country: 'US', // Assuming country is always 'US'
    };
    const recepientEmail = { email };
    const paymentPayload = {
      ...paymentLocation,
      ...recepientEmail,
      expectedPrice: calculateTotal().getAmount(),
      gift: true,
    }
    //gift: true will need to be added back if we use that api module
    if (promoCode) {
      paymentPayload.promoCode = promoCode;
    }
    return handleSubmitPayment(nonce, paymentPayload);
  };

  // const handlePayment = (e) => {
  //   e.preventDefault()
  //   if (!email || !validateEmail(email)) {
  //     setEmailError(strings.InvalidEmailFormat);
  //     setCurrentStep(null);
  //   } else {
  //     if (!instance) {
  //       console.error('Payment form instance not initialized yet');
  //       setCurrentStep(null);
  //     } else {
  //       setSubmittingPayment(true);
  //       const total = getTotal(price, totalTax);
  //       if (total.getAmount() === 0) {
  //         return submitPayment('dummy');
  //       } else {
  //         instance.requestPaymentMethod((err, payload) => {
  //           if (payload) {
  //             const total = price.add(totalTax).toFormat('0.00');

  //             window.gtag('event', 'Order Completed', {
  //               total: total,
  //               subtotal: price.toFormat('0.00'),
  //               tax: totalTax.toFormat('0.00'),
  //               products:
  //               {
  //                 brand: 'Ballnamic',
  //                 name: 'Ballnamic Gift Voucher',
  //                 quantity: 1,
  //                 price: '39.00'
  //               },
  //             });
  //             // tried to get payload into the call, but it was all card info

  //             window.analytics.track("Order Completed", {
  //               total: total,
  //               subtotal: price.toFormat('0.00'),
  //               tax: totalTax.toFormat('0.00'),
  //               products:
  //               {
  //                 brand: 'Ballnamic',
  //                 name: 'Ballnamic Gift Voucher',
  //                 quantity: 1,
  //                 price: '39.00'
  //               },
  //             }); // is this works here we need to add data layer event as well
  //             return submitPayment(payload.nonce);
  //           } else {
  //             if (err) {
  //               let message = err.message;
  //               if (message === 'No payment method is available.') {
  //                 message = strings.noPaymentMethodSelected;
  //               }
  //               setBraintreeError(message);
  //             }
  //             setCurrentStep(null);
  //             setSubmittingPayment(false);
  //           }
  //         });
  //       }
  //     }
  //   }
  // };
  const handlePayment = (e) => {
    e.preventDefault();
    if (!email || !validateEmail(email)) {
        setEmailError(strings.InvalidEmailFormat);
        return; // Early return to prevent further execution
    }

    // Assuming calculateTotal() returns a Dinero object
    const totalPrice = calculateTotal();

    // Check if total price is greater than zero
    if (!totalPrice || totalPrice.isZero()) {
        // Total price is zero, proceed with submission without payment method
        console.log('Proceeding without payment method as total is zero');
        submitPayment('no-nonce-required'); // A dummy nonce or similar mechanism
        return;
    }

    // For non-zero totals, ensure payment method is selected
    if (!instance) {
        console.error('Payment form instance not initialized');
        setBraintreeError(strings.noPaymentMethodSelected);
        setSubmittingPayment(false);
        return;
    }

    setSubmittingPayment(true);
    instance.requestPaymentMethod((err, payload) => {
        if (err) {
            console.error('Error requesting payment method:', err.message);
            setBraintreeError(err.message || strings.noPaymentMethodSelected);
            setSubmittingPayment(false); // Stop submission process
            return; // Early return to prevent further execution
        }

        if (payload) {
            // Proceed with payment submission using payload.nonce
            console.log('Payment method nonce:', payload.nonce);
            submitPayment(payload.nonce); // Use the nonce for submission
        } else {
            console.error('No payment method selected');
            setBraintreeError(strings.noPaymentMethodSelected);
            setSubmittingPayment(false); // Stop submission process
        }
    });
};
  const Invoice = ({ totalTax, price }) => {

    // let total;
    // if (price && totalTax) {
    //   total = getTotal(price, totalTax);
    // }
    let totalPrice = calculateTotal(); // Ensure this function accounts for promo discounts
    return (
      <table className="invoice-table" aria-label={strings.invoice}>
        <thead>
          <tr>
            <th></th>
            <th>{strings.price}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ballnamic</td>
            <td>{price ? price.toFormat('$0,0.00') : strings.updating}</td>
          </tr>
          {promoDiscountAmount > 0 && (
            <tr>
              <td>Promo Discount</td>
              <td>-{Dinero({ amount: promoDiscountAmount, currency: 'USD' }).toFormat('$0,0.00')}</td>
            </tr>
          )}
          <tr>
            <td id="tax-amount-label">{strings.salesTax}</td>
            <td aria-labelledby="tax-amount-label">{totalTax ? totalTax.toFormat('$0,0.00') : strings.updating}</td>
          </tr>
          <tr>
            <td id="total-label">{strings.total}</td>
            <td aria-labelledby="total-label">{totalPrice ? totalPrice.toFormat('$0,0.00') : strings.updating}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <Modal
      className="voucher-modal"
      isOpened={isOpened}
      onClose={handleClose}
      title={titleModal}
      shouldCloseOnOutsideClick={false}
    >
      {currentStep === 'FORM_STEP' && (
        <div className="email-form">
          <div className="helper-text">{strings.PleaseEnterEmailAddress}</div>

          <div className="input-container">
            <label className="input-label" htmlFor="email-id">
              {strings.Email}
            </label>
            <input id="email-id" name="email" type="email" value={email} onChange={handleEmailChange} />
            {emailError && <ValidationError>{emailError}</ValidationError>}
          </div>

          <div className="input-container">
            <label className="input-label" htmlFor="confirmEmail-id">
              {strings.ConfirmEmail}
            </label>
            <input
              id="confirmEmail-id"
              name="confirmEmail"
              type="email"
              value={confirmEmail}
              onChange={handleConfirmEmailChange}
            />
            {confirmEmailError && <ValidationError>{confirmEmailError}</ValidationError>}
          </div>
          <div className="buttonNext-container">
            <button
              onClick={handleFormSubmit}
              className="button button--color--primary"
              aria-label="Next"
              disabled={!allowNext}
            >
              {strings.buttonNext}
            </button>
          </div>
        </div>
      )}
      {currentStep === 'LOCATION_STEP' && (
        <>
          {locationFormError && <ValidationError>{locationFormError}</ValidationError>}
          <BillingLocationForm onSubmit={handleLocationFormSubmit} />
        </>
      )}
      {currentStep === 'SUMMARY_STEP' && (
        <div>
          <div className="helper-text">
            <ul>
              <li>{strings.paymentHelperTextOne}</li>
              <li>{strings.paymentHelperTextTwo}</li>
              <li>{strings.paymentHelperTextThree}</li>
              <li>{strings.paymentHelperTextFour}</li>
            </ul>
          </div>
          <div className="emailReceiveWrapper">
            <div className="">{strings.EmailThatWillReceiveVoucher}</div> <div className="summaryEmail">{email}</div>
          </div>
          <Invoice totalTax={totalTax} price={price} discount={promoDiscountAmount}/>
          {/* {!promoCodeApplied && ( */}
          {/* // <PromoCodeForm onSubmit={handlePromoCodeSubmit}/> */}
          <PromoCodeForm key={promoCodeApplied ? 'applied' : 'not-applied'} onPromoCodeSubmit={handlePromoCodeSubmit}/>
          {/* )} */}
          {promoCodeError && <ValidationError>{promoCodeError}</ValidationError>}

          <div id="braintree-container"></div>
          {braintreeError && <ValidationError>{braintreeError}</ValidationError>}
          {formLoaded ? (
            <div className="summaryPayButton">
              <button
                className="button button--color--primary gift-button"
                onClick={handlePayment}
                aria-label={strings.PurchaseGift}
                disabled={submittingPayment}
              >
                {strings.PurchaseGift}
              </button>
            </div>
          ) : null}
        </div>
      )}
    </Modal>
  );
};

export default VoucherForm;