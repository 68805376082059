import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ScrollToTop from '../common/components/ScrollToTop';
import LocalizedStrings from 'react-localization';
import LandingPageHeader from '../landing-page/LandingPageHeader';
import { fetchLoggedInUser } from '../auth/auth-actions';
import LoadingIndicator from '../common/components/LoadingIndicator';
import GiftVoucher from './images/ballnamic_gift_voucher_new.png';
import CTAFooter from '../common/components/CTAFooter';
import Footer from '../common/components/Footer';
import VoucherForm from './VoucherForm';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const strings = new LocalizedStrings({
  en: {
    voucherHeading: 'Ballnamic Online Ball Fitting',
    voucherText1:
      'Give the gift of the most innovative golf ball fitting experience in the game.',
    voucherText2: 'Each Ballnamic fitting voucher includes five unique fittings to be used within one year of purchase.',
    voucherText3:
      'After completing your purchase, a gift voucher and instructions on how to redeem will be delivered to the email of your choice within minutes.',
    buttonGiftBallFitting: 'Gift a Ball Fitting',
    buttonLoginGiftBallFitting: 'Login to Gift a Ball Fitting',
  },
});

const Gift = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.auth.loggedInUser);
  const token = useSelector(state => state.auth.token);
  const fetchingLoggedInUser = useSelector(state => state.auth.fetchLoggedInUser.get('loading'));
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false); // add a state variable to control the modal

  const handleButtonClick = () => {
    window.analytics.track("Checkout Step Viewed", { step: 'fitting_voucher_1'});

    window.gtag('event', 'Checkout Step Viewed', { step: 'fitting_voucher_1'});
    if (!loggedInUser) {
      localStorage.setItem('giftBallFitting', 'true');
      history.push('/registration');
    } else {
      window.analytics.identify(loggedInUser.get('id'),{
        firstName: loggedInUser.get('first_name'),
        lastName: loggedInUser.get('last_name'),
        email: loggedInUser.get('oidcData').get('email'),
      });
      window._cio.identify({
        id: loggedInUser.get('id'),
        email: loggedInUser.get('oidcData').get('email'),
        first_name: loggedInUser.get('first_name'),
        last_name: loggedInUser.get('last_name'),
      });
      localStorage.removeItem('giftBallFitting');
      setIsModalOpen(true); // open the modal when the button is clicked
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // close the modal
  };

  useEffect(() => {
    window.analytics.page();
    if (token) {
      dispatch(fetchLoggedInUser());
    }
  }, [dispatch, token]);

  if (fetchingLoggedInUser) {
    return <LoadingIndicator />;
  }

  return (
    <ScrollToTop>
      <div className="ballnamic--gift-page">
        <LandingPageHeader isLoggedIn={!!loggedInUser} loggedInUser={loggedInUser} />
        <div className="container gift-page">
          <div className="gift-inner">
            <div className="gift-voucher-img-wrapper">
              <img src={GiftVoucher} className="gift-voucher-img" alt={strings.voucherHeading} />
            </div>
            <div className="gift-voucher-text-wrapper">
              <h1>{strings.voucherHeading}</h1>
              <h2>$39</h2>
              <p>{strings.voucherText1}</p>
              <p>{strings.voucherText2}</p>
              <p>{strings.voucherText3}</p>
            </div>
            <div className="gift-voucher-button-wrapper">
              <button className="button button--color--primary gift-button" onClick={handleButtonClick}>
                {strings.buttonGiftBallFitting}
                {/* {loggedInUser ? strings.buttonGiftBallFitting : strings.buttonLoginGiftBallFitting} */}
              </button>
              <div className="gift-voucher-text-wrapper-login">
              {!loggedInUser && <p>Create an account or <Link to="/login">Login</Link> to Gift a Ball Fitting </p>}
              </div>
            </div>
            
          </div>
        </div>

        <VoucherForm isOpened={isModalOpen} onCancel={handleModalClose} />

        <CTAFooter />
        <Footer />
      </div>
    </ScrollToTop>
  );
};

export default Gift;