import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PaymentForm from '../../../common/components/PaymentForm';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import { getBraintreeToken, getPrices, submitResultsPayment } from '../../../payment/payment-actions';
import Dinero from 'dinero.js';
import { triggerGTMEvent } from '../../../common/utils/gtm';
import { Redirect } from 'react-router-dom';

class ResultPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: props.isOpen,
      showPaymentModal: false,
    };
  }
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    triggerGTMEvent('viewed_payment_modal');
    dispatch(getBraintreeToken());
    dispatch(getPrices());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isModalOpen: this.props.isOpen });
    }
  }

  handleSubmitPayment = (nonce, payload) => {
    const { dispatch, redirectUrl } = this.props;
    dispatch(submitResultsPayment(nonce, payload)).then(response => {
      
      
      if (response.response.status === 200) {
        this.setState({ isModalOpen: false });
        if (redirectUrl) {
          return <Redirect to={redirectUrl} />;
        }
      }
    });
  };

  render() {
    const { braintreeToken, prices, submitResultsPayment, onCancel, resultTeaser, getResultTeaser } = this.props;
    if (!prices || !getResultTeaser.get('loaded')) {
      return <LoadingIndicator />;
    }
    const price = Dinero({ amount: prices.getIn(['PLAYER_RESULTS', 'USD']), currency: 'USD' });

    return (
      <PaymentForm
        isOpened={this.state.isModalOpen}
        onSubmit={this.handleSubmitPayment}
        onCancel={onCancel}
        token={braintreeToken}
        price={price}
        submittingPayment={submitResultsPayment.get('loading')}
        hasPaymentError={!!submitResultsPayment.get('error')}
        resultTeaser={resultTeaser}
        resultTeaserError={getResultTeaser.get('error')}
      />
    );
  }
}

export default connect(state => ({
  braintreeToken: state.payment.braintreeToken,
  prices: state.payment.prices,
  submitResultsPayment: state.payment.submitResultsPayment,
}))(ResultPayment);
