import React from 'react';
import Svg, { Path } from 'svgs';

const Settings = ({ width, height, fill }) => {
  return (
    <Svg
      height={height || 24}
      width={width || 24}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M13.998 7c-.553 0-1.08-.443-1.291-.952-.21-.508-.15-1.194.24-1.585l.707-.706a1.001 1.001 0 00-1.414-1.415l-.707.707c-.391.391-1.076.451-1.584.241-.507-.21-.951-.737-.951-1.29V1a1 1 0 10-2 0v1c0 .553-.442 1.08-.95 1.291s-1.192.15-1.583-.24l-.709-.707a1 1 0 00-1.414 1.414l.709.708c.39.39.459 1.079.287 1.596-.17.518-.69.954-1.241.948L1 7a1.001 1.001 0 000 2h1.001c.552 0 1.087.438 1.331.925.245.486.188 1.159-.207 1.546l-.783.77a1 1 0 001.414 1.415l.708-.708c.391-.391 1.075-.451 1.584-.24.508.211.95.738.95 1.291V15a.999.999 0 102 0v-1.001c0-.553.444-1.08.951-1.289.508-.211 1.193-.15 1.584.24l.707.707a1 1 0 001.415-1.414l-.708-.708c-.391-.391-.451-1.076-.24-1.584S13.445 9 13.998 9h1a1 1 0 100-2h-1zm-6 4c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z" />
    </Svg>
  );
};

export default Settings;
