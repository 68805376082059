import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LocalizedStrings from 'react-localization';
import { Carousel } from 'react-responsive-carousel';
import TrophyIcon from '../../../common/components/icons/Trophy';
import GolfCart from '../../../common/components/icons/GolfCart';
import confidenceIntervalStrings from './confidence-interval-strings';
import GolfBallTee from '../../../common/components/icons/GolfBallTee';
import GolfHole from '../../../common/components/icons/GolfHole';

const strings = new LocalizedStrings({
  en: {
    introText: `Ballnamic utilizes advanced golf ball evaluation and modelling techniques that have the following estimated accuracy levels, using a 95% confidence interval:`,
    confidenceIntervalDescription: `The 95% confidence interval is an estimation of the probability that the reported values or figures fall within a specified range.  We would expect the ball performance to fall within the interval estimates 95% of the time based upon the testing and data provided and employed. Click on Testing Deep Dive in the Library to learn more about our testing methods.`,
    closingText: `The Trophies {icon} you will see on the Results page are designed as a visual guide to indicate the desired performance in each category. A trophy {icon} does not signify that the ball is definitively the best performer. Any balls within the specified confidence interval may produce equivalent or better performance for an individual golfer.`,
    youAssumeAnyRisk: `Ballnamic is intended to provide additional insight for a golfer to consider and possibly discuss with her/his preferred equipment advisor. Any other use of the results provided by Ballnamic are strongly discouraged - for example as a factor in support of or in opposition to any actual or proposed Rule of Golf, Local Rule or as a factor in handicapping.`,
    ourSelectionProcess: `Our selection process is designed to identify estimated ball performance for a wide range of golfers. We currently test urethane cover balls which are 3-piece construction or greater at a price point of $20 or higher, however, we are unable to test all balls in the marketplace.  We update our database at least twice a year, based on the timing of commercial launch dates. Ballnamic users acknowledge that there could be up to a 6-month period when a new version of a ball that we have included in our database is not in the database. We test the primary light finish of each ball (typically white), so actual results may vary with alternative color ball models.`,
    ballnamicRecommends: `Ballnamic recommends 5 balls that are estimated to be a good overall match based on your inputs.  Users are encouraged to consider the characteristics and relative performance of the top 5 or more balls identified on the Results page and consider which of the 10+ categories of performance may be more or less meaningful to you, the golfer, when considering which golf ball(s) you may wish to play.`,
    ballnamicProvides: `Ballnamic fitting results provide estimated performance projections based on individual user input, modelling, algorithm-based approximations, and product testing, along with data and assumptions developed by Ballnamic.  Results and recommendations rely upon the foregoing and other variables to provide estimated projections, and necessarily include uncertainty as identified balls may not perform as modelled and estimated. Ballnamic’s projections do not assure or guarantee that any ball will outperform any other ball.  The Ballnamic tool does not endorse any particular ball(s).`,
    getYourResults: 'Before you tee off',
    whichBalls: 'Which balls are in the Ballnamic database?',
    howToUse: 'How to Use Your Recommendations',
    getResults: 'Get Results',
    agree: 'I Agree',
  },
});

function ConfidenceRangeDisclaimer({ onSubmit, hasAcceptedDisclaimer }) {
  const [step, setStep] = useState(0);
  return (
    <div className="container">
      <Carousel
        className="disclaimer-carousel"
        showStatus={false}
        showThumbs={false}
        swipeable={hasAcceptedDisclaimer}
        showArrows={hasAcceptedDisclaimer}
        showIndicators={hasAcceptedDisclaimer}
        dynamicHeight
        selectedItem={step}
      >
        <div className="carousel-container">
          <div className="get-results--payment__wrapper">
            <GolfHole size={48} fill="#5057FF" />
            <h1>{strings.getYourResults}</h1>
            <div className="get-results--disclaimer__empty-state">
              <div>{strings.introText}</div>
              <ul>
                <li>
                  {confidenceIntervalStrings.carryDistanceRange} {confidenceIntervalStrings.carryDistance}
                </li>
                <li>
                  {confidenceIntervalStrings.windDistanceRange} {confidenceIntervalStrings.windDistance}
                </li>
                <li>
                  {confidenceIntervalStrings.driverMaxHeightRange} {confidenceIntervalStrings.driverMaxHeight}
                </li>
                <li>
                  {confidenceIntervalStrings.ironCarryRange} {confidenceIntervalStrings.ironCarry}
                </li>
                <li>
                  {confidenceIntervalStrings.ironRollRange} {confidenceIntervalStrings.ironRoll}
                </li>
                <li>
                  {confidenceIntervalStrings.ironWorkabilityScoreRange} {confidenceIntervalStrings.ironWorkabilityScore}
                </li>
                <li>
                  {confidenceIntervalStrings.flierPreventionRange} {confidenceIntervalStrings.flierPrevention}
                </li>
                <li>
                  {confidenceIntervalStrings.wedgeSpinRange} {confidenceIntervalStrings.wedgeSpin}
                </li>
              </ul>
              <div>{strings.confidenceIntervalDescription}</div>
              <br />
              <div>{strings.formatString(strings.closingText, { icon: <TrophyIcon fill="#EDAE49" /> })}</div>
              <br />
              <div>{strings.youAssumeAnyRisk}</div>
            </div>
            {hasAcceptedDisclaimer ? (
              <button type="button" className="button button--color--primary" onClick={onSubmit}>
                {strings.getResults}
              </button>
            ) : (
              <button
                type="button"
                className="button button--color--primary"
                onClick={() => {
                  setStep(1);
                  window.scrollTo(0, 0);
                }}
              >
                {strings.agree}
              </button>
            )}
          </div>
        </div>
        <div className="container">
          <div className="get-results--payment__wrapper">
            <GolfBallTee size={48} fill="#5057FF" />
            <h1>{strings.whichBalls}</h1>
            <div className="get-results--disclaimer__empty-state">{strings.ourSelectionProcess}</div>
            {hasAcceptedDisclaimer ? (
              <button type="button" className="button button--color--primary" onClick={onSubmit}>
                {strings.getResults}
              </button>
            ) : (
              <button
                type="button"
                className="button button--color--primary"
                onClick={() => {
                  setStep(2);
                  window.scrollTo(0, 0);
                }}
              >
                {strings.agree}
              </button>
            )}
          </div>
        </div>
        <div className="container">
          <div className="get-results--payment__wrapper">
            <GolfCart size={48} fill="#5057FF" />
            <h1>{strings.howToUse}</h1>
            <div className="get-results--disclaimer__empty-state">
              <p>{strings.ballnamicRecommends}</p>
              <p>{strings.ballnamicProvides}</p>
            </div>
            {
              <button
                type="button"
                id="get-results-button"
                data-testid="final-get-results-button"
                className="button button--color--primary"
                onClick={e => {
                  window.scrollTo(0, 0);
                  onSubmit(e);
                }}
              >
                {strings.getResults}
              </button>
            }
          </div>
        </div>
      </Carousel>
    </div>
  );
}

ConfidenceRangeDisclaimer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hasAcceptedDisclaimer: PropTypes.bool.isRequired,
};

export default ConfidenceRangeDisclaimer;
