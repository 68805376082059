import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { trackPromoCode } from '../utils/trackPromoCode';

const PromoCodeTracker = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);
  const getPromoCode = useSelector(({ payment }) => payment.getPromoCode);

  useEffect(() => {
    if (!getPromoCode.get('loaded') && !getPromoCode.get('loading') && !getPromoCode.get('error')) {
      trackPromoCode(location, dispatch, loggedInUser);
    }
  }, [dispatch, getPromoCode, location, loggedInUser]);

  return null;
};

export default PromoCodeTracker;
