import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchStaticResults, resetRelativeResults, calculateRelativeResults } from '../questionnaire-actions';
import compose from 'lodash/flowRight';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import ResultList from './ResultList';
import LocalizedStrings from 'react-localization';
import { triggerGTMEvent } from '../../../common/utils/gtm';
import Header from '../../../common/components/Header';
import Logo from '../../../common/components/logo/Logo';
import CoBrand from '../../../common/components/CoBrand';

const strings = new LocalizedStrings({
  en: {
    results: 'Results',
  },
});

class StaticResults extends Component {
  state = {
    favoritedBall: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    //window.analytics.page("Viewed Results");
    

    triggerGTMEvent('viewed_emailed_results');

    const resultsId = this.props.match.params.resultsId;
    
    // FIXME: Order ID event 
    window.analytics.page("Previous Fitting Results", {
      fitting_id: resultsId,
    });
    dispatch(fetchStaticResults(resultsId));
  }

  componentDidUpdate(prevProps) {
    const { results } = this.props;
    if (results && prevProps.results !== results && results.getIn(['inputs', 'favorite_ball'])) {
      this.setFavorite(results.getIn(['inputs', 'favorite_ball']));
    } else if (results && prevProps.results !== results) {
      // default to first ball in results
      this.setFavorite(results.getIn(['results', 'raw', '0', 'Ball']));
    }
  }

  setFavorite = favorite => {
    const { dispatch, results } = this.props;

    this.setState({ favoritedBall: favorite }, () => {
      if (this.state.favoritedBall === null) {
        dispatch(resetRelativeResults());
      } else {
        dispatch(
          calculateRelativeResults({
            favorite_ball: this.state.favoritedBall,
            raw_df: results.getIn(['results', 'raw']),
          })
        );
      }
    });
  };

  renderMobileNav() {
    const { results } = this.props;

    return (
      <div className="mobile-nav">
        <div className="mobile-nav--static-results__logo">
          <Link to="/" aria-label={strings.landingPage}>
            <Logo fill="#5057FF" width={115} height={32} />
          </Link>
        </div>
        <div className="mobile-nav__flex-full static-results">
          {results.getIn(['coBrandData', 'svg_src']) && results.getIn(['coBrandData', 'name']) && (
            <CoBrand
              nonFitter
              className="user-nav__co-brand"
              imageUrl={results.getIn(['coBrandData', 'svg_src'])}
              name={results.getIn(['coBrandData', 'name'])}
              height={32}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    const { results, relativeResults } = this.props;

    if (!results) {
      return <LoadingIndicator />;
    }

    return (
      <div className="static-results__wrapper">
        <Header
          coBrandImageUrl={results.getIn(['coBrandData', 'svg_src'])}
          coBrandName={results.getIn(['coBrandData', 'name'])}
          nonFitter
        />
        {this.renderMobileNav()}
        <div className="static-results__fitter-comments">
          <h1>{results.get('player_name') ? `${results.get('player_name')}'s ${strings.results}` : strings.results}</h1>
          {!!results?.get('fitter_comments') && (
            <>
              <h2>Additional Comments</h2>
              <p style={{ whiteSpace: 'pre-wrap' }}>{results.get('fitter_comments')}</p>
            </>
          )}
        </div>
        <ResultList
          results={results.getIn(['results', 'results'])}
          tooltipText={results.getIn(['results', 'helperText'])}
          relativeResults={relativeResults || results.getIn(['results', 'relative'])}
          favoritedBall={this.state.favoritedBall}
          currentBall={results.getIn(['inputs', 'current_ball'])}
          // Older results won't have raw data, which is needed to set favorite
          setFavorite={results.getIn(['results', 'raw']) ? this.setFavorite : null}
          isSixIronFitting={results.get('six_iron_fitting')}
        />
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    results: state.questionnaire.staticResults,
    relativeResults: state.questionnaire.relativeResults,
  }))
)(StaticResults);
