import React from 'react';
import LocalizedStrings from 'react-localization';
import ConfirmationModal from '../../../../common/components/ConfirmationModal';

const strings = new LocalizedStrings({
  en: {
    purchaseNewBallnamicSession: 'Purchase New Ballnamic Session',
    newPurchaseText: `By "Purchasing a new session", which will allow you to re-enter your inputs and purchase additional Ballnamic fittings. Your previous results will still be saved in My Profile.`,
    backToResults: 'Go Back',
    confirm: 'Confirm',
  },
});

const ShowPurchaseNewSessionModal = ({ isopen, onConfirm, onPurchase }) => {
  return (
    <ConfirmationModal
      isOpened={isopen}
      content={strings.newPurchaseText}
      titleText={strings.purchaseNewBallnamicSession}
      buttonText={strings.backToResults}
      leftButtonText={strings.confirm}
      onConfirm={onConfirm}
      onPurchase={onPurchase}
    />
  );
};

export default ShowPurchaseNewSessionModal;
