import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import { Helmet } from 'react-helmet';
import { fetchMyResultsList } from '../../user-actions';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import TableHistoryIcon from '../../../common/components/icons/TableHistory';
import localstorage from 'store2';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    myResults: 'My Results',
    name: 'Name',
    resultsLink: 'Results Link',
    dateAndTime: 'Date & Time',
    noResults: 'No results history',
    myself: 'Me',
  },
});

class MyResults extends Component {
  constructor(props) {
    super(props);
    this.promoCode = JSON.parse(localstorage.get('promoCode'));
  }
  componentDidMount() {
    this.props.dispatch(fetchMyResultsList());
  }

  renderResultsTable() {
    const { myResultsList } = this.props;

    /* @design The zero-content placeholder needs styling */
    if (myResultsList.size === 0) {
      return (
        <div className="results-history--zero-content">
          <h3>{strings.noResults}</h3>
          <TableHistoryIcon fill="#576975" size={48} />
        </div>
      );
    }

    return (
      <table className="fitter-list results-history-table">
        <thead>
          <tr>
            <th>{strings.name}</th>
            <th>{strings.resultsLink}</th>
            <th>{strings.dateAndTime}</th>
          </tr>
        </thead>
        <tbody>
          {myResultsList.map(result => (
            <tr key={result.get('url')}>
              <td>{result.get('name') || strings.myself}</td>
              <td>
                <a
                  href={this.promoCode?.code ? result.get('url') + `?p=${this.promoCode?.code}` : result.get('url')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {result.get('url')}
                </a>
              </td>
              <td>{moment(result.get('created_at')).format('MM/DD/YYYY @ hh:mma')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { loaded } = this.props;
    return (
      <div className="page--my-profile">
        <Helmet>
          <title>{`${strings.myResults} - ${strings.title} `}</title>
        </Helmet>
        <div className="container small">
          <h1>{strings.myResults}</h1>
          {!loaded ? <LoadingIndicator /> : this.renderResultsTable()}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  myResultsList: state.user.myResultsList,
  loaded: state.user.fetchMyResultsList.get('loaded'),
}))(MyResults);
