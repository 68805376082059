import { combineReducers } from 'redux';
import {
  GET_BRAINTREE_TOKEN,
  GET_PRICES,
  SUBMIT_RESULTS_PAYMENT,
  GET_BILLING_HISTORY,
  GET_SALES_TAX,
  RESET_SALES_TAX,
  GET_PROMO_CODE,
  RESET_SUBMIT_RESULTS_PAYMENT,
} from './payment-action-constants';
import Immutable from 'immutable';
import getApiReducer from '../common/utils/getApiReducer';
import Dinero from 'dinero.js';

export default combineReducers({
  submitResultsPayment: getApiReducer(SUBMIT_RESULTS_PAYMENT, [RESET_SUBMIT_RESULTS_PAYMENT]),
  getBillingHistory: getApiReducer(GET_BILLING_HISTORY),
  getSalesTax: getApiReducer(GET_SALES_TAX, [RESET_SALES_TAX]),
  getPromoCode: getApiReducer(GET_PROMO_CODE),
  braintreeToken: (state = null, action) => {
    if (action.type === `${GET_BRAINTREE_TOKEN}_SUCCESS`) {
      return Immutable.fromJS(action.json.clientToken);
    }
    return state;
  },
  promoCode: (state = null, action) => {
    if (action.type === `${GET_PROMO_CODE}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  prices: (state = null, action) => {
    if (action.type === `${GET_PRICES}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  billingHistory: (state = null, action) => {
    if (action.type === `${GET_BILLING_HISTORY}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  totalTax: (state = null, action) => {
    if (action.type === `${GET_SALES_TAX}_REQUEST` || action.type === `RESET_SALES_TAX`) {
      return null;
    }
    if (action.type === `${GET_SALES_TAX}_SUCCESS`) {
      return Dinero({ amount: parseInt(action.json.totalTax * 100, 10), current: 'USD' });
    }
    return state;
  },
});
