import React from 'react';
import Divider from '../Divider';
import LocalizedStrings from 'react-localization';
import ArrowDownTrend from '../../components/icons/ArrowDownTrend';
import FatCheck from '../../components/icons/FatCheck';

const strings = new LocalizedStrings({
  en: {
    teaserSubtext:
      "With rigorous and unbiased testing and state-of-the-art modeling, Ballnamic's fitting process will propel your game to new heights.",
    getMyResults: 'Get My Results',
    features1: 'Generate up to 5 unique ball fittings',
    features2: '1-Year access to generate your 5 ball fittings',
    features3: 'Results permanently stored in profile',
    price: '$39',
    priceSubtext: 'for 5 fittings',
    didYouKnow: 'There is room for improvement',
  },
});

const ResultTeaser = props => {
  const { resultTeaser, onClickNext } = props;

  const showTeaser = resultTeaser.get('use_metric')?.includes('teaser');
  const showTestimonial = resultTeaser.get('use_metric')?.includes('testimonial');

  const renderTeaserSection = resultTeaser => {
    const teaserInfo = showTeaser && resultTeaser.get('teaser_df')?.find(teaser => teaser.get('display_teaser'));

    return (
      <div className="teaser-metric__wrapper">
        <p className="did-you-know">{strings.didYouKnow}</p>

        <div className="teaser-metric">
          <p>{teaserInfo.get('highlight_metric')}</p>
          {teaserInfo.get('highlight_arrow') && <ArrowDownTrend size={32} />}
        </div>

        <p className="teaser-metric--long-text">{teaserInfo.get('long_text')}</p>
      </div>
    );
  };

  const renderTestimonialSection = resultTeaser => {
    const testimonialInfo = showTestimonial && resultTeaser.get('testimonial_df').get(0);

    return (
      <>
        <p className="testimonial-quote">{`"${testimonialInfo.get('quote')}"`}</p>

        <div className="testimonial-author">
          <p>{testimonialInfo.get('person')}</p>
          <p className="testimonial-author-divider">|</p>
          <img className="testimonial-author-logo" src={testimonialInfo.get('icon')} alt="Testimonial Author Logo" />
        </div>
      </>
    );
  };

  return (
    <div className="result-teaser">
      <p className="modal--subtext">{strings.teaserSubtext}</p>
      <Divider />

      {showTeaser && renderTeaserSection(resultTeaser)}
      {showTestimonial && renderTestimonialSection(resultTeaser)}

      <Divider />

      <div className="feature-list">
        <div className="feature">
          <span className="check">
            <FatCheck fill="#248227" />
          </span>
          <p>{strings.features1}</p>
        </div>
        <div className="feature">
          <span className="check">
            <FatCheck fill="#248227" />
          </span>
          <p>{strings.features2}</p>
        </div>
        <div className="feature">
          <span className="check">
            <FatCheck fill="#248227" />
          </span>
          <p>{strings.features3}</p>
        </div>
      </div>

      <div className="price">
        <p className="price-text">{strings.price}</p>
        <p className="price-subtext">{strings.priceSubtext}</p>
      </div>

      <button
        type="button"
        className="button button--color--primary"
        data-testid="result-teaser-next-button"
        onClick={() => onClickNext()}
      >
        {strings.getMyResults}
      </button>
    </div>
  );
};

export default ResultTeaser;
