import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router';
import { withFormik, Form } from 'formik';
import YourProfile from './YourProfile';
import Stepper from './Stepper';
import compose from 'lodash/flowRight';
import DriverDetails from './DriverDetails';
import IronDetails from './IronDetails';
import WedgeDetails from './WedgeDetails';
import FinishingTouches, { COST_MAX, COST_MIN } from './FinishingTouches';
import Results from './Results';
import ScrollToTop from '../../common/components/ScrollToTop';
import NotFound from '../../NotFound';
import { calculateIronLaunchConditions, getResultTeaser as getResultTeaserAction } from './questionnaire-actions';
import { areLaunchConditionsKnown } from './utils';
import pick from 'lodash/pick';
import * as Yup from 'yup';
import LocalizedStrings from 'react-localization';
import { UNKNOWN_BRAND_STRING } from './YourProfile';
import MaxResultsReachedModal from './Results/ResultList/MaxResultsReachedModal';
import ShowPurchaseNewSessionModal from './Results/ResultList/PurchaseNewSession';
import ResultPayment from './Results/ResultPayment';
import clamp from '../../common/utils/clamp';
import LoadingIndicator from '../../common/components/LoadingIndicator';

const strings = new LocalizedStrings({
  en: {
    pleaseSelectAModel: 'Please select a model',
    pleaseSelectAYear: 'Please select a year',
  },
});

class Questionnaire extends Component {
  state = {
    showMaxResultsReachedModal: false,
    showPurchaseNewSessionModal: false,
    submittedDisclaimer: false,
    showPaymentForm: false,
    teaserPreloadStarted: false,
    zipcode: '',
  };

  componentDidMount() {
    const { history, recipients, loggedInUser, dispatch } = this.props;
    const step = this.getCurrentStep();
    // get-started step is required before going to any of the other steps.
    // If name isn't set, get-started form wasn't completed.
    if (loggedInUser.get('type') !== 'player' && step !== 'get-started' && !recipients) {
      history.replace('/my-fitting/get-started');
    } else if (!step) {
      history.replace('/my-fitting/your-profile');
    }

    if (
      loggedInUser.get('type') === 'player' &&
      loggedInUser.get('has_active_payment') &&
      loggedInUser.get('results_remaining') <= 0 &&
      this.getCurrentStep() !== 'results'
    ) {
      this.setState({ showMaxResultsReachedModal: true });
    }
    if (!this.state.teaserPreloadStarted) {
      dispatch(getResultTeaserAction(this.getPerformancePayload(this.props)));
      this.setState({ teaserPreloadStarted: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { loggedInUser, driverLaunchConditionsCalculated, savedValues, dispatch, location } = this.props;

    if (
      loggedInUser.get('type') === 'player' &&
      loggedInUser.get('has_active_payment') &&
      loggedInUser.get('results_remaining') <= 0 &&
      location.pathname !== prevProps.location.pathname &&
      this.getCurrentStep() !== 'results'
    ) {
      this.setState({ showMaxResultsReachedModal: true });
    }

    // Driver launch conditions calculations update values that are used as inputs for
    // iron launch conditions, so update those too unless the user has set those to be set manually.
    if (!prevProps.driverLaunchConditionsCalculated && driverLaunchConditionsCalculated) {
      const ironConditionsAreKnown = areLaunchConditionsKnown('iron', savedValues.toJS(), loggedInUser);
      if (!ironConditionsAreKnown) {
        dispatch(
          calculateIronLaunchConditions(
            pick(savedValues.toJS(), [
              'iron_distance',
              'iron_spin_type',
              'iron_launch_type',
              'driver_ball_speed',
              'driver_launch_angle',
              'driver_spin_rate',
              'typical_alt',
              'typical_temp',
            ])
          )
        );
      }
    }
  }

  getCurrentStep() {
    const {
      location: { pathname },
    } = this.props;

    const matches = pathname.match(/\/my-fitting\/([^/]+).*/);

    return matches && matches[1];
  }

  isPaymentRequired(props) {
    const { loggedInUser } = props;

    return loggedInUser.get('type') === 'player' && !loggedInUser.get('has_active_payment');
  }

  getPerformancePayload(props) {
    const { values, loggedInUser } = props;
    const payload = values;

    payload.current_ball = `${payload.year} ${payload.brand} ${payload.model}`;
    delete payload.year;
    delete payload.brand;
    delete payload.model;
    delete payload.recipients;
    delete payload.handicap;
    delete payload.driver_distance;
    delete payload.driver_spin_type;
    delete payload.driver_launch_type;
    delete payload.iron_distance;
    delete payload.iron_spin_type;
    delete payload.iron_launch_type;
    delete payload.driver_launch_conditions_known;
    delete payload.iron_launch_conditions_known;

    if (!loggedInUser.get('show_price_preference')) {
      payload.cost_preference = 'none';
    }

    // Clamping values in case out-of-range values from a previous version are still in state
    payload.cost_lower_limit = clamp(payload.cost_lower_limit, COST_MIN, COST_MAX);
    payload.cost_upper_limit = clamp(payload.cost_upper_limit, COST_MIN, COST_MAX);

    payload.dtc_ind = 1;
    payload.house_ind = 1;

    const userSavedBrands = loggedInUser?.getIn(['userBrandSetting', 'settings'])?.toJS();
    const brands = userSavedBrands?.brands
      .map(brand => {
        return brand.on ? brand.brandName : null;
      })
      .filter(item => item);
    values.brands = brands;
    payload.brands = brands;

    return payload;
  }

  render() {
    const { history, resultTeaser, getResultTeaser } = this.props;
    const step = this.getCurrentStep();

    return (
      <>
        {!getResultTeaser.get('loaded') ? (
          <LoadingIndicator />
        ) : (
          <div className="questionnaire-stepper__wrapper">
            <ScrollToTop
              shouldScrollToTop={(oldPath, newPath) => {
                const graphRoutePrefix = '/my-fitting/results/explore';
                return !(oldPath.startsWith(graphRoutePrefix) && newPath.startsWith(graphRoutePrefix));
              }}
            >
              <MaxResultsReachedModal
                isopen={this.state.showMaxResultsReachedModal}
                onConfirm={() => {
                  this.setState({ showMaxResultsReachedModal: false });
                  history.replace('/my-fitting/results');
                }}
                onPurchase={() => {
                  this.setState({ showMaxResultsReachedModal: false });
                  this.setState({ showPurchaseNewSessionModal: true });
                }}
              />
              <ShowPurchaseNewSessionModal
                isopen={this.state.showPurchaseNewSessionModal}
                onConfirm={() => {
                  this.setState({ showPurchaseNewSessionModal: false });
                  history.replace('/my-fitting/results');
                }}
                onPurchase={() => {
                  this.setState({ showPurchaseNewSessionModal: false });
                  this.setState({ showPaymentForm: true });
                }}
              />
              <ResultPayment
                isOpen={this.state.showPaymentForm}
                onCancel={() => {
                  this.setState({ showPaymentForm: false });
                  history.replace('/my-fitting/results');
                }}
                getResultTeaser={getResultTeaser}
                resultTeaser={resultTeaser}
                redirectUrl={'my-fitting/profile'}
              />
              <Form>
                <Stepper step={step} zipcode={this.state.zipcode}/>
                <Switch>
                  <Route exact path="/my-fitting/your-profile" component={YourProfile} />
                  <Route exact path="/my-fitting/driver" component={DriverDetails} />
                  <Route exact path="/my-fitting/irons" component={IronDetails} />
                  <Route exact path="/my-fitting/wedges" component={WedgeDetails} />
                  <Route exact path="/my-fitting/finishing-touches" component={FinishingTouches} />
                  <Route path="/my-fitting/results" component={Results} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </Form>
            </ScrollToTop>
          </div>
        )}
      </>
    );
  }
}

export default compose(
  connect(state => ({
    savedValues: state.questionnaire.values,
    recipients: state.questionnaire.recipients,
    loggedInUser: state.auth.loggedInUser,
    driverLaunchConditionsCalculated: state.questionnaire.calculateDriverLaunchConditions.get('loaded'),
    getResultTeaser: state.questionnaire.getResultTeaser,
    resultTeaser: state.questionnaire.resultTeaser,
  })),
  withFormik({
    mapPropsToValues: props => props.savedValues.toJS(),
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      model: Yup.string().when('brand', {
        is: val => !!val && val !== UNKNOWN_BRAND_STRING,
        then: Yup.string().required(strings.pleaseSelectAModel),
        otherwise: Yup.string(),
      }),
      year: Yup.string().when('brand', {
        is: val => !!val && val !== UNKNOWN_BRAND_STRING,
        then: Yup.string().required(strings.pleaseSelectAYear),
        otherwise: Yup.string(),
      }),
    }),
  }),
  withRouter
)(Questionnaire);
