import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import hash from 'object-hash';
import { connect } from 'react-redux';
import {
  calculateFlights,
  calculateRelativeResults,
  fetchResults,
  resetRelativeResults,
  resetSendResults,
  resetQuestionnaire,
  sendResults,
  sendSelfResults,
  fetchLatestResults,
  acceptDisclaimer,
} from '../questionnaire-actions';
import { fetchLoggedInUser } from '../../../auth/auth-actions';
import compose from 'lodash/flowRight';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import ResultList from './ResultList';
import Explore from './Explore';
import { NavLink, Switch, Route, Redirect } from 'react-router-dom';
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import LocalizedStrings from 'react-localization';
import { withRouter } from 'react-router-dom';
import SendResultsModal from './SendResultsModal';
import SendIcon from '../../../common/components/icons/Send';
import RefreshIcon from '../../../common/components/icons/Refresh';
import ResultPayment from './ResultPayment';
import isEqual from 'lodash/isEqual';
import clamp from '../../../common/utils/clamp';
import { COST_MIN, COST_MAX } from '../FinishingTouches';
import ConfidenceRangeDisclaimer from './ConfidenceRangeDisclaimer';
import MaxResultsAlmostReachedModal from './MaxResultsAlmostReachedModal';
import ResultsAnimatedLoadingIndicator from './ResultsAnimatedLoadingIndicator';
import localstorage from 'store2';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    results: 'Results',
    compareResults: 'Compare Results',
    exploreResults: 'Explore Results',
    startOver: 'Start Over',
    areYouSure: 'Are you sure?',
    sendResults: 'Send Results',
  },
});

const RESTART_CONFIRMATION_MODAL = 'RESTART_CONFIRMATION_MODAL';
const SEND_RESULTS_MODAL = 'SEND_RESULTS_MODAL';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedModal: null,
      favoritedBall: null,
      submittedDisclaimer: false,
      showMaximumRequestsAlmostReachedModal: false,
      loadingIndicatorComplete: false,
      teaserPreloadStarted: false,
      isModalOpen: false,
    };
  }

  sendResultsRef = React.createRef();
  confirmationModalRef = React.createRef();
  compareResultsRef = React.createRef();

  componentDidMount() {
    const { submittingCalcLaunchConditions, loggedInUser } = this.props;

    if (loggedInUser.get('type') !== 'player' && !submittingCalcLaunchConditions) {
      this.fetchResults();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, results, loggedInUser, submittingCalcLaunchConditions } = this.props;

    if (
      loggedInUser.get('type') !== 'player' &&
      !isEqual(this.getPerformancePayload(prevProps), this.getPerformancePayload(this.props))
    ) {
      this.fetchResults();
    }

    // if (loggedInUser.get('transaction') && !prevProps.loggedInUser.get('transaction')) {
    //   const transaction = loggedInUser.get('transaction');
    //   var tax = Number(transaction.get('tax') || 0).toFixed(2);
    //   var total = Number(transaction.get('price') || 0).toFixed(2);
    //   var subTotal = (total - tax).toFixed(2);
    //   var discountPost = (39.00 - subTotal).toFixed(2);
    //   var orderIdPost = transaction.get('transactionId') || null;
    //   window.analytics.track("Order Completed", {
    //     fitting_id: null,
    //     order_id: orderIdPost,
    //     total: total,
    //     discount: discountPost,
    //     subtotal: subTotal,
    //     coupon: transaction.get('coupon') || null,
    //     tax: tax,
    //     products:
    //     {
    //       brand: 'Ballnamic',
    //       name: 'Ballnamic Player Fitting',
    //       price: '39.00',
    //       quantity: 1,
    //       coupon: transaction.get('coupon') || null,
    //       discount_amount: (39.00 - subTotal).toFixed(2)
    //     },
    //   });
    //   // data layer
    //   // old call
    //   window.gtag('event', 'purchase', {
    //     transaction_id: transaction.get('transactionId'),
    //     value: Number(transaction.get('price') || 0),
    //     coupon: transaction.get('coupon') || null,
    //     tax: Number(transaction.get('tax') || 0),
    //     currency: 'USD',
    //     items: [
    //       {
    //         item_name: 'Ballnamic Player Fitting',
    //         price: Number(transaction.get('price') || 0),
    //         quantity: 1,
    //       },
    //     ],
    //   });
    //   // new call
    //   window.gtag('event', 'Order Completed', {
    //     fitting_id: null,
    //     order_id: orderIdPost,
    //     total: total,
    //     discount: discountPost,
    //     subtotal: subTotal,
    //     coupon: transaction.get('coupon') || null,
    //     tax: tax,
    //     products:
    //     {
    //       brand: 'Ballnamic',
    //       name: 'Ballnamic Player Fitting',
    //       price: '39.00',
    //       quantity: 1,
    //       coupon: transaction.get('coupon') || null,
    //       discount_amount: (39.00 - subTotal).toFixed(2)
    //     },
    //   });
    // }
    

    if (results && prevProps.results !== results) {
      this.setState({ favoritedBall: results.getIn(['results', 0, 'info', 'Ball']) }, () => {
        dispatch(
          calculateRelativeResults({
            favorite_ball: this.state.favoritedBall,
            raw_df: results.get('raw'),
          })
        );
      });

      dispatch(
        calculateFlights(
          this.getPerformancePayload(this.props),
          results
            .get('results')
            .map(result => result.getIn(['info', 'Ball']))
            .toJS()
        )
      );
    }

    if (this.isPaymentRequired(prevProps) && !this.isPaymentRequired(this.props)) {
      this.fetchResults();
    }

    if (
      loggedInUser.get('type') === 'player' &&
      !this.isPaymentRequired(this.props) &&
      !prevState.submittedDisclaimer &&
      this.state.submittedDisclaimer &&
      !submittingCalcLaunchConditions
    ) {
      this.fetchResults();
    }

    if (
      loggedInUser.get('type') === 'player' &&
      !this.isPaymentRequired(this.props) &&
      this.state.submittedDisclaimer &&
      prevProps.submittingCalcLaunchConditions &&
      !submittingCalcLaunchConditions
    ) {
      this.fetchResults();
    }

    if (!prevProps.fetchResultsError && this.props.fetchResultsError) {
      if (this.props.fetchResultsError.get('statusCode') === 402) {
        dispatch(fetchLoggedInUser());
      }
    }
  }

  updateLoadingBarComplete() {
    this.setState({ loadingIndicatorComplete: true });
  }

  fetchResults() {
    const { dispatch, results, loggedInUser } = this.props;

    const values = this.getPerformancePayload(this.props);

    // Skip fetch if we already have results for these values
    if (results && results.get('valuesHash')) {
      const valuesHash = hash(values);
      if (valuesHash === results.get('valuesHash')) {
        return;
      }
    }

    if (loggedInUser.get('type') === 'player') {
      if (loggedInUser.get('results_remaining') === 2) {
        this.setState({ showMaximumRequestsAlmostReachedModal: true });
      } else if (loggedInUser.get('results_remaining') > 0) {
        dispatch(fetchResults(values));
      } else {
        dispatch(fetchLatestResults());
      }
    } else {
      dispatch(fetchResults(values));
    }
  }

  getPerformancePayload(props) {
    const { values, loggedInUser } = props;
    const payload = values.toJS();

    payload.current_ball = `${payload.year} ${payload.brand} ${payload.model}`;
    delete payload.year;
    delete payload.brand;
    delete payload.model;
    delete payload.recipients;
    delete payload.handicap;
    delete payload.driver_distance;
    delete payload.driver_spin_type;
    delete payload.driver_launch_type;
    delete payload.iron_distance;
    delete payload.iron_spin_type;
    delete payload.iron_launch_type;
    delete payload.driver_launch_conditions_known;
    delete payload.iron_launch_conditions_known;

    if (!loggedInUser.get('show_price_preference')) {
      payload.cost_preference = 'none';
    }

    // Clamping values in case out-of-range values from a previous version are still in state
    payload.cost_lower_limit = clamp(payload.cost_lower_limit, COST_MIN, COST_MAX);
    payload.cost_upper_limit = clamp(payload.cost_upper_limit, COST_MIN, COST_MAX);

    payload.dtc_ind = 1;
    payload.house_ind = 1;

    const userSavedBrands = loggedInUser?.getIn(['userBrandSetting', 'settings'])?.toJS();
    const brands = userSavedBrands?.brands
      .map(brand => {
        return brand.on ? brand.brandName : null;
      })
      .filter(item => item);
    values.brands = brands;
    payload.brands = brands;

    return payload;
  }

  setFavorite = favorite => {
    const { dispatch, results } = this.props;

    this.setState({ favoritedBall: favorite }, () => {
      if (this.state.favoritedBall === null) {
        dispatch(resetRelativeResults());
      } else {
        dispatch(
          calculateRelativeResults({
            favorite_ball: this.state.favoritedBall,
            raw_df: results.get('raw'),
          })
        );
      }
    });
  };

  sendResults = sendResultsValues => {
    const { dispatch, recipients, loggedInUser } = this.props;
    const { fitter_comments } = sendResultsValues;
    const { favoritedBall } = this.state;
    const code = JSON.parse(localstorage.get('promoCode'));
    const promoCode = code?.code ? code?.code : undefined;
    if (loggedInUser.get('type') === 'player') {
      dispatch(sendSelfResults(this.getPerformancePayload(this.props), favoritedBall), promoCode);
    } else {
      dispatch(
        sendResults(recipients, this.getPerformancePayload(this.props), favoritedBall, fitter_comments, promoCode)
      );
    }
  };

  isPaymentRequired(props) {
    const { loggedInUser } = props;

    return loggedInUser.get('type') === 'player' && !loggedInUser.get('has_active_payment');
  }

  renderModals() {
    const { sendingResults, sentResults, dispatch, history, loggedInUser, results } = this.props;

    const values = this.getPerformancePayload(this.props);

    return (
      <>
        <MaxResultsAlmostReachedModal
          isOpen={this.state.showMaximumRequestsAlmostReachedModal}
          onClose={() => {
            this.setState({ showMaximumRequestsAlmostReachedModal: false });
            if (!results) {
              dispatch(fetchLatestResults());
            }
          }}
          onConfirm={() => {
            this.setState({ showMaximumRequestsAlmostReachedModal: false });
            dispatch(fetchResults(values));
          }}
        />
        <ConfirmationModal
          isOpened={this.state.displayedModal === RESTART_CONFIRMATION_MODAL}
          buttonText={strings.startOver}
          titleText={strings.areYouSure}
          onClose={() => {
            this.setState({ displayedModal: null });
            this.confirmationModalRef.current.focus();
          }}
          onConfirm={() => {
            dispatch(resetQuestionnaire());
            history.push('/my-fitting/get-started');
          }}
        />
        <SendResultsModal
          isOpened={this.state.displayedModal === SEND_RESULTS_MODAL}
          onClose={() => {
            dispatch(resetSendResults());
            this.setState({ displayedModal: null });
            this.sendResultsRef.current.focus();
          }}
          loggedInUser={loggedInUser}
          onSubmit={this.sendResults}
          sending={sendingResults}
          success={sentResults}
        />
      </>
    );
  }

  renderNav() {
    const { results } = this.props;

    const disabledStyles = { opacity: results ? 1 : 0.5, pointerEvents: results ? 'all' : 'none' };

    return (
      <div className="results--sub-nav">
        <div className="results__sub-nav--left">
          <NavLink
            className="results__sub-nav--left__link"
            style={disabledStyles}
            to="/my-fitting/results"
            exact
            disabled={results}
            ref={this.compareResultsRef}
          >
            {strings.compareResults}
          </NavLink>
          <NavLink
            className="results__sub-nav--left__link"
            style={disabledStyles}
            to="/my-fitting/results/explore/"
            strict
            disabled={results}
          >
            {strings.exploreResults}
          </NavLink>
        </div>
        <div className="results__sub-nav--right">
          <button
            className="results__sub-nav--right__button button--icon"
            style={disabledStyles}
            type="button"
            onClick={() => this.setState({ displayedModal: SEND_RESULTS_MODAL })}
            ref={this.sendResultsRef}
          >
            <div className="icon__wrapper">
              <SendIcon />
            </div>
            <span>{strings.sendResults}</span>
          </button>
          <button
            className="results__sub-nav--right__button button--icon"
            style={disabledStyles}
            type="button"
            onClick={() => this.setState({ displayedModal: RESTART_CONFIRMATION_MODAL })}
            ref={this.confirmationModalRef}
          >
            <div className="icon__wrapper">
              <RefreshIcon />
            </div>
            <span>{strings.startOver}</span>
          </button>
        </div>
      </div>
    );
  }

  renderContents() {
    const {
      flights,
      loadingResults,
      results,
      relativeResults,
      values,
      submittingCalcLaunchConditions,
      dispatch,
      hasAcceptedDisclaimer,
      getResultTeaser,
      resultTeaser,
    } = this.props;

    if (!this.state.submittedDisclaimer) {
      
      window.analytics.track("Player Profile Agreement", {
        fitting_id: null,
      });
      window.gtag('event', 'Player Profile Agreement', {
        fitting_id: null,
      });
      
      return (
        <ConfidenceRangeDisclaimer
          onSubmit={() => {
            window.analytics.track("Checkout Step Viewed",{
              fitting_id: null,
              checkout_step: 1
            });
            window.gtag('event', 'Checkout Step Viewed', {
              fitting_id: null,
              checkout_step: 1
            });
            dispatch(acceptDisclaimer());
            this.setState({ submittedDisclaimer: true });
            this.setState({ isModalOpen: true });
          }}
          hasAcceptedDisclaimer={hasAcceptedDisclaimer}
        />
      );
    }

    if (this.isPaymentRequired(this.props) && !this.state.teaserPreloadStarted) {
      this.setState({ teaserPreloadStarted: true });
    }

    if (!this.state.loadingIndicatorComplete && this.isPaymentRequired(this.props)) {
      return <ResultsAnimatedLoadingIndicator onComplete={() => this.setState({ loadingIndicatorComplete: true })} />;
    }

    if (this.isPaymentRequired(this.props)) {
      return (
        <ResultPayment
          isOpen={this.state.isModalOpen}
          onCancel={() => this.setState({ submittedDisclaimer: false })}
          getResultTeaser={getResultTeaser}
          resultTeaser={resultTeaser}
        />
      );
    }

    return (
      <div className="results__wrapper">
        {this.renderModals()}
        {this.renderNav()}
        {loadingResults || submittingCalcLaunchConditions || !results ? (
          <div className="results--loading-indicator">
            <LoadingIndicator />
            <h4>Calculating the best balls for your game...</h4>
          </div>
        ) : (
          <Switch>
            <Route exact path="/my-fitting/results">
              <ResultList
                tooltipText={results.get('helperText')}
                results={results.get('results')}
                relativeResults={relativeResults}
                questionnaireValues={values}
                favoritedBall={this.state.favoritedBall}
                setFavorite={this.setFavorite}
                isSixIronFitting={results.get('six_iron_fitting')}
              />
            </Route>
            <Route exact path="/my-fitting/results/explore/:shotType">
              <Explore
                results={results.get('results')}
                questionnaireValues={values}
                favoritedBall={this.state.favoritedBall}
                flights={flights}
              />
            </Route>
            <Redirect from="/my-fitting/results/explore/" to="/my-fitting/results/explore/driver" exact />
          </Switch>
        )}
      </div>
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{`${strings.results} - ${strings.title}`}</title>
        </Helmet>
        {this.renderContents()}
      </>
    );
  }
}

export default compose(
  connect(state => ({
    loadingResults: state.questionnaire.fetchResults.get('loading'),
    fetchResultsError: state.questionnaire.fetchResults.get('error'),
    results: state.questionnaire.results,
    flights: state.questionnaire.flights,
    relativeResults: state.questionnaire.relativeResults,
    values: state.questionnaire.values,
    recipients: state.questionnaire.recipients,
    sendingResults: state.questionnaire.sendResults.get('loading'),
    sentResults: state.questionnaire.sendResults.get('loaded'),
    loggedInUser: state.auth.loggedInUser,
    submittingCalcLaunchConditions:
      state.questionnaire.calculateDriverLaunchConditions.get('loading') ||
      state.questionnaire.calculateIronLaunchConditions.get('loading'),
    hasAcceptedDisclaimer: state.questionnaire.hasAcceptedDisclaimer,
    getResultTeaser: state.questionnaire.getResultTeaser,
    resultTeaser: state.questionnaire.resultTeaser,
  })),
  withRouter
)(Results);
