import Client, { JwtPlugin, oauthPlugin } from '@synapsestudios/fetch-client';
import { createPlugin } from '@synapsestudios/fetch-client-goalie';
import store from './store';
import config from './config';
import { logout } from './auth/auth-redirects';
import { version } from '../package.json';
import { updateToken } from './auth/auth-actions-clientless';

const client = new Client({
  url: config.apiUrl,
  timeout: 20000,
  ...['get', 'post', 'put', 'patch', 'delete'].reduce((acc, method) => ({
    ...acc,
    [method]: {
      headers: {
        'client-token': process.env.REACT_APP_CLIENT_TOKEN || 'INVALID_TOKEN',
      },
    },
  }), {}),
});

client.addPlugin(new JwtPlugin());
client.setJwtTokenGetter(() => {
  return store.getState().auth.token;
});

// Goalie config
client.addPlugin(createPlugin(`^${version}`, store));

client.on('TOKEN_REFRESH_FAILED', () => {
  logout();
});

client.addPlugin(oauthPlugin);
client.setConfig({
  client_id: config.auth.clientId,
  refresh_path: `${config.apiUrl}token`,
});

client.setBearerTokenGetter(() => store.getState().auth.token);
client.setRefreshTokenGetter(() => store.getState().auth.refreshToken);

client.setUsedRefreshTokens([]);

client.setOnRefreshResponse(async ({ access_token, refresh_token, id_token }) => {
  store.dispatch(updateToken(id_token, refresh_token));
  client.setBearerTokenGetter(() => id_token);
  client.setRefreshTokenGetter(() => refresh_token);
});

export default client;
