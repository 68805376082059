import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { getBillingHistory } from '../../../payment/payment-actions';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import Pager from '../../../common/components/Pager';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Dinero from 'dinero.js';
import querystring from 'query-string';
import compose from 'lodash/flowRight';

import Visa from '../../../common/components/icons/Visa';
import Mastercard from '../../../common/components/icons/Mastercard';
import Amex from '../../../common/components/icons/Amex';
import Discover from '../../../common/components/icons/Discover';
import DinersClub from '../../../common/components/icons/DinersClub';
import Jcb from '../../../common/components/icons/Jcb';
import UnionPay from '../../../common/components/icons/UnionPay';
import PayPal from '../../../common/components/icons/PayPal';

const strings = new LocalizedStrings({
  en: {
    billingHistory: 'Billing History',
    date: 'Date',
    name: 'Name',
    amount: 'Amount',
    paymentMethod: 'Payment Method',
    noBillingHistory: 'No billing history',
  },
});

const DEFAULT_PAGE_SIZE = 20;

class BillingHistory extends Component {
  componentDidMount() {
    this.fetchPage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchPage();
    }
  }

  fetchPage() {
    const { dispatch, location } = this.props;
    const searchQuery = querystring.parse(location.search);
    dispatch(getBillingHistory(searchQuery.page || 0, this.getPageSize()));
  }

  getPageSize() {
    const { location } = this.props;
    const searchQuery = querystring.parse(location.search);
    return searchQuery.page_size || DEFAULT_PAGE_SIZE;
  }

  formatAmount(transaction) {
    const price = Dinero({
      amount: parseInt(transaction.get('amount').replace(/[^\d]/, ''), 10),
      currency: transaction.get('currencyIsoCode'),
    });
    return price.toFormat('$0,0.00');
  }

  getSvgForBrand(brand) {
    if (brand === 'Visa') {
      return <Visa />;
    }
    if (brand === 'MasterCard') {
      return <Mastercard />;
    }
    if (brand === 'American Express') {
      return <Amex />;
    }
    if (brand === 'Discover') {
      return <Discover />;
    }
    if (brand === 'DinersClub') {
      return <DinersClub />;
    }
    if (brand === 'Jcb') {
      return <Jcb />;
    }
    if (brand === 'UnionPay') {
      return <UnionPay />;
    }
  }

  render() {
    const { transactions, loadingBillingHistory } = this.props;

    if(!transactions) return null;
    if(loadingBillingHistory) return <LoadingIndicator />;

    return (
      <div className="billing-history__wrapper">
        <h3>{strings.billingHistory}</h3>
        {transactions.get('total') === 0 ? (
          <div>{strings.noBillingHistory}</div>
        ) : (
          <table className="table billing-history">
            <thead>
              <tr>
                <th>{strings.date}</th>
                <th>{strings.name}</th>
                <th>{strings.paymentMethod}</th>
                <th>{strings.amount}</th>
              </tr>
            </thead>
            <tbody>
              {transactions.get('results').map((transaction, id) => (
                <tr key={id}>
                  <td className="date">{moment(transaction.get('createdAt')).format('L')}</td>
                  <td className="name">Ballnamic</td>
                  <td className="method">
                    {transaction.get('paymentInstrumentType') === 'paypal_account' ? (
                      <PayPal />
                    ) : (
                      <>
                        <span>{this.getSvgForBrand(transaction.getIn(['creditCard', 'cardType']))}</span>
                        <span>ending in {transaction.getIn(['creditCard', 'last4'])}</span>
                      </>
                    )}
                  </td>
                  <td className="amount">{this.formatAmount(transaction)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="pagination-td" colSpan={4}>
                  <Pager pageSize={this.getPageSize()} totalItems={transactions.get('total')} />
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({
    transactions: state.payment.billingHistory,
    loadingBillingHistory: state.payment.getBillingHistory.get('loading'),
  }))
)(BillingHistory);
