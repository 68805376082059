import React from 'react';
import Svg, { Polygon } from 'svgs';

const ArrowDownTrend = ({ size, fill }) => {
  return (
    <Svg
      height={size || 16}
      width={size || 16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Polygon fillRule="evenodd" points="11 9.586 11 5 13 5 13 13 5 13 5 11 9.586 11 3.293 4.707 4.707 3.293" />
    </Svg>
  );
};

export default ArrowDownTrend;
