import { Link } from 'react-router-dom';
import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';

const strings = new LocalizedStrings({
  en: {
    giftTitle: 'Gift a Ball Fitting',
    giftSubText: `Give the gift of game-changing insights with a Ballnamic Fitting Voucher.`,
    giftButtonText: 'Gift Now',
    buyTitle: 'Play with More Confidence',
    buySubText: `Hit the course with information about your ball previously reserved for Tour players. All for only $39.`,
    buyButtonText: 'Start My Fitting',
  },
});

function CTAFooter({ loggedInUser }) {
  return (
    <div className="bottom-cta__wrapper">
      <div className="container landing-page">
      <div className="bottom-cta--inner-left">
          <h1>{strings.buyTitle}</h1>
          <p>{strings.buySubText}</p>
          <Link to={loggedInUser ? '/my-fitting' : '/registration'} className="button button--color--white">
          {strings.buyButtonText}
          </Link>
        </div>
        <div className="bottom-cta--inner-split"></div>
        <div className="bottom-cta--inner-right">
          <h1>{strings.giftTitle}</h1>
          <p>{strings.giftSubText}</p>
          <Link to={'/gift'} className="button button--color--white">
            {strings.giftButtonText}
          </Link>
        </div>
      </div>
    </div>
  );
}
export default connect(state => ({
  loggedInUser: state.auth.loggedInUser,
}))(CTAFooter);
