import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Slider from '../../common/forms/Slider';
import { connect as formikConnect } from 'formik';
import { setQuestionnaireValues, calculateDriverLaunchConditions } from './questionnaire-actions';
import LocalizedStrings from 'react-localization';
import compose from 'lodash/flowRight';
import DriverLaunchConditions from './DriverLaunchConditions';
import ArrowRight from '../../common/components/icons/ArrowRight';
import ArrowLeft from '../../common/components/icons/ArrowLeft';
import TooltipIcon from '../../common/components/TooltipIcon';
import { calculateLaunchConditionsIfNeeded } from './utils';
import TrajectoryRecommendation from './TrajectoryRecommendation';
import pick from 'lodash/pick';
// import AltandTemp for segment tracking

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    driverDetails: 'Driver Details',
    enterDriverDetails: 'Enter your driver details here',
    ballSpeed: 'Ball Speed (mph)',
    speedUnits: 'mph',
    launchAngle: 'Launch Angle (deg)',
    spinRate: 'Spin Rate (rpm)',
    spinRateUnits: 'rpm',
    heightPreference: 'Height Preference',
    lowTrajectory: 'Lowest\nTrajectory',
    lowMidTrajectory: 'Low-Mid\nTrajectory',
    midTrajectory: 'Mid Trajectory',
    midHighTrajectory: 'Mid-High Trajectory',
    highTrajectory: 'Highest\nTrajectory',
    windPerformance: 'Wind Performance',
    windPerformanceSubtext: 'With a driver, how important is the performance of the golf ball in windy conditions?',
    windPerformanceSliderMinimum: 'Not Important',
    windPerformanceSliderModerate: 'Moderate Importance',
    windPerformanceSliderMaximum: 'High Importance',
    doNotKnowDriverConditions: `I don't know my driver launch conditions`,
    ballSpeedTooltip: `We know that higher initial ball speeds produced by the player initiates more rise in the flight. Our algorithms will pair you with a ball that optimizes the aerodynamics based on the speed you generate. For faster speeds, this could be a ball with less lift to prevent wasting energy in vertical flight (aka “ballooning”). For slower speeds, this could be a ball with more lift to keep it carrying in the air longer.`,
    launchAngleTooltip: `Your initial launch angle is primarily driven by your driver fitting and swing mechanics, but we have seen balls change initial launch angle by over 1°. Consider the ball as the final equipment piece to synergistically optimize your driving.`,
    spinRateTooltip: `If you are struggling to get optimal spin through your driver fitting, Ballnamic will help you find a ball that unlocks lost performance. Not only do we see down-range flight differences between balls, but we’ve also seen initial spin vary by as much as 600-rpm!`,
    heightPreferenceTooltip: `This question is where you marry your flight preference with performance. Do you like to see your driver come out at a certain ‘window’? Also consider, in windy or firm conditions, you may want the shallower landing angle that comes from a lower trajectory. Or, maybe you need the increased carry that a higher trajectory provides.`,
    heightPreferenceSubtext: `If you prioritize driver carry, consider selecting a higher trajectory preference. Conversely, a lower trajectory preference may be more appropriate in firm or windy conditions.`,
    windConditionsTooltip: `The Ballnamic fitting algorithm simulates and estimates how different balls may react in different wind conditions. How you answer this question will help us discern the aerodynamic flight stability you may desire.`,
  },
});

const heightPrefLabelMap = {
  0: strings.lowTrajectory,
  1: strings.lowMidTrajectory,
  2: strings.midTrajectory,
  3: strings.midHighTrajectory,
  4: strings.highTrajectory,
};

const windConditionsLabelMap = {
  0: strings.windPerformanceSliderMinimum,
  1: strings.windPerformanceSliderModerate,
  2: strings.windPerformanceSliderMaximum,
};

class DriverDetails extends Component {
  state = {
    displayedModal: null,
  };

  componentDidUpdate(prevProps) {
    const { driver_distance, driver_spin_type, driver_launch_type } = this.props.formik.values;
    if (
      driver_distance !== prevProps.formik.values.driver_distance ||
      driver_spin_type !== prevProps.formik.values.driver_spin_type ||
      driver_launch_type !== prevProps.formik.values.driver_launch_type
    ) {
      this.calculateDriverLaunchConditions();
    }

    const { setFieldValue } = this.props.formik;
    if (prevProps.loggedInUser.get('six_iron_fitting') !== this.props.loggedInUser.get('six_iron_fitting')) {
      setFieldValue('iron_distance', 145);
      setFieldValue('iron_spin_type', 'mid');
      setFieldValue('iron_launch_type', 'mid');
    }
  }

  calculateDriverLaunchConditions() {
    const { dispatch } = this.props;
    dispatch(setQuestionnaireValues(this.props.formik.values));
    dispatch(
      calculateDriverLaunchConditions(
        pick(this.props.formik.values, [
          'driver_distance',
          'driver_spin_type',
          'driver_launch_type',
          'typical_alt',
          'typical_temp',
        ])
      )
    );
  }
  segment_driver_post(){
    const { formik: { values } } = this.props;
    var tr_clean = null;
    if(values.driver_height_pref_calc){
      tr_clean = heightPrefLabelMap[values.driver_height_pref_calc].replace("\n"," ").trim();
    }
    const tp_clean = heightPrefLabelMap[values.driver_height_pref].replace("\n"," ").trim();
    const wi_clean = windConditionsLabelMap[values.driver_wind_imp].replace("\n", " ").trim();
    var dSpinPost = values.driver_spin_type.toString();
    dSpinPost = dSpinPost.charAt(0).toUpperCase() + dSpinPost.slice(1);
    var dLaunchAnglePost = values.driver_launch_type.toString();
    dLaunchAnglePost = dLaunchAnglePost.charAt(0).toUpperCase() + dLaunchAnglePost.slice(1);

    window.analytics.track("Player Profile Driver", {
      total_distance: values.driver_distance,
      launch_angle: dLaunchAnglePost,
      launch_angle_degree: values.driver_launch_angle,
      spin_rate: dSpinPost,
      spin_rate_rpm: values.driver_spin_rate,
      ball_speed_mph: values.driver_ball_speed,
      recommended_trajectory: tr_clean,
      trajectory_preference: tp_clean,
      wind_importance:wi_clean ,
      fitting_id: null
    });
    window.gtag('event', 'Player Profile Driver', {
      total_distance: values.driver_distance,
      launch_angle: dLaunchAnglePost,
      launch_angle_degree: values.driver_launch_angle,
      spin_rate: dSpinPost,
      spin_rate_rpm: values.driver_spin_rate,
      ball_speed_mph: values.driver_ball_speed,
      recommended_trajectory: tr_clean,
      trajectory_preference: tp_clean,
      wind_importance:wi_clean ,
      fitting_id: null
    });
  }


  render() {
    const {
      dispatch,
      history,
      savedValues,
      loggedInUser,
      formik: { values, setFieldValue },
    } = this.props;

    return (
      <div className="questionnaire__wrapper">
        <Helmet>
          <title>{`${strings.driverDetails} - ${strings.title}`}</title>
        </Helmet>
        <div className="questionnaire--inner">
          <div className="questionnaire--header">
            <h1>{strings.driverDetails}</h1>
            <h4>{strings.enterDriverDetails}</h4>
          </div>
          <div className="questionnaire-blocks__container">
            <DriverLaunchConditions />
            <Slider
              label={strings.heightPreference}
              labelComponent={<TrajectoryRecommendation />}
              subLabel={strings.heightPreferenceSubtext}
              tooltip={<TooltipIcon>{strings.heightPreferenceTooltip}</TooltipIcon>}
              displayValue={heightPrefLabelMap[values.driver_height_pref]}
              sliderProps={{
                min: 0,
                max: 4,
                step: 1,
                marks: {
                  1: <div className="slider--mark text">{heightPrefLabelMap[0]}</div>,
                  4: <div className="slider--mark text">{heightPrefLabelMap[4]}</div>,
                },
                dots: true,
                value: values.driver_height_pref,
                onChange: value => setFieldValue('driver_height_pref', value),
              }}
              textMarks
            />
            <Slider
              label={strings.windPerformance}
              subLabel={strings.windPerformanceSubtext}
              tooltip={<TooltipIcon>{strings.windConditionsTooltip}</TooltipIcon>}
              displayValue={windConditionsLabelMap[values.driver_wind_imp]}
              sliderProps={{
                min: 0,
                max: 2,
                step: 1,
                marks: {
                  0: <div className="slider--mark text">{windConditionsLabelMap[0]}</div>,
                  2: <div className="slider--mark text">{windConditionsLabelMap[2]}</div>,
                },
                dots: true,
                value: values.driver_wind_imp,
                onChange: value => setFieldValue('driver_wind_imp', value),
              }}
              textMarks
            />
          </div>
          <div className="questionnaire--button__wrapper">
            <button
              className="button button--color--primary button--theme--outline button--icon"
              type="button"
              onClick={() => {
                dispatch(setQuestionnaireValues(values));
                calculateLaunchConditionsIfNeeded('driver', values, savedValues.toJS(), dispatch, loggedInUser);
                calculateLaunchConditionsIfNeeded('iron', values, savedValues.toJS(), dispatch, loggedInUser);
                this.segment_driver_post();
                history.push('/my-fitting/your-profile');
              }}
            >
              <ArrowLeft />
              <div>Back</div>
            </button>
            <button
              className="button button--color--primary button--icon"
              type="button"
              onClick={() => {
                dispatch(setQuestionnaireValues(values));
                calculateLaunchConditionsIfNeeded('driver', values, savedValues.toJS(), dispatch, loggedInUser);
                calculateLaunchConditionsIfNeeded('iron', values, savedValues.toJS(), dispatch, loggedInUser);
                //console.log(this.props);
                this.segment_driver_post();

                // suppose to include recommended from 'calculate' button, but This
                // value changes as the user changes radio buttons after calculation
                history.push('/my-fitting/irons');
              }}
            >
              <div>Next</div>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    submittingCalcLaunchConditions: state.questionnaire.calculateDriverLaunchConditions.get('loading'),
    savedValues: state.questionnaire.values,
  })),
  formikConnect
)(DriverDetails);
