import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import compose from 'lodash/flowRight';
import 'rc-slider/assets/index.css';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import MultiButtonField from '../../common/forms/MultiButtonField';
import Dropdown from './Dropdown';
import {
  fetchBrands,
  fetchModels,
  fetchYears,
  setQuestionnaireValues,
  resetModels,
  resetYears,
} from './questionnaire-actions';
import { connect as formikConnect } from 'formik';
import ArrowRight from '../../common/components/icons/ArrowRight';
import { validateProfileStep, calculateLaunchConditionsIfNeeded, areLaunchConditionsKnown } from './utils';
import AltitudeAndTemperature from './AltitudeAndTemperature';
const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    yourProfile: 'Your Profile',
    setUpYourProfile: 'Set up your golfing profile',
    customerDetails: 'Customer Details',
    handicap: 'Handicap',
    selectCurrentBall: 'Select Current Ball',
    brand: 'Brand',
    model: 'Model',
    year: 'Year',
    scratchOrBetter: 'Scratch or better',
  },
});

export const UNKNOWN_BRAND_STRING = `Don't Know / It Varies`;

class YourProfile extends Component {
  state = {
    zipCode: '',
  }
  // callback to receive zipcode from child component for Segment tracking
  // took out     this.props.onZipcodeCalculated(zipcode);
  handleZipcodeCalculated = (zipcode) => {
    this.setState({zipCode: zipcode});
  };


  componentDidMount() {
    const {
      dispatch,
      formik: { values },
    } = this.props;

    dispatch(fetchBrands());
    if (values.brand) {
      dispatch(fetchModels(values.brand));

      if (values.model) {
        dispatch(fetchYears(values.brand, values.model));
      }
    }
  }

  fetchModels = brand => {
    const { dispatch } = this.props;
    dispatch(fetchModels(brand));
  };

  fetchYears = (brand, model) => {
    const { dispatch } = this.props;
    dispatch(fetchYears(brand, model));
  };

  componentDidUpdate(prevProps) {
    const { years, dispatch } = this.props;
    const savedValues = this.props.values;
    const { setFieldValue } = this.props.formik;
    // When new set of years loaded, select the latest one
    if (
      years &&
      prevProps.years !== years &&
      (!savedValues.get('year') || !years.find(year => year.toString() === savedValues.get('year').toString()))
    ) {
      setFieldValue('year', Math.max.apply(Math.max, years.toJS()));
    }
    // Reset selected year when brand changed
    const prevFormValues = prevProps.formik.values;
    const formValues = this.props.formik.values;
    if (prevFormValues.brand !== formValues.brand) {
      setFieldValue('year', '');
    }
    // Clear dependent dropdowns when a parent dropdown is set back to null
    if (
      prevFormValues.brand &&
      prevFormValues.brand !== formValues.brand &&
      (!formValues.brand || formValues.brand === UNKNOWN_BRAND_STRING)
    ) {
      setFieldValue('model', '');
      setFieldValue('year', '');
      dispatch(resetModels());
      dispatch(resetYears());
    }
    if (prevFormValues.model && !formValues.model) {
      setFieldValue('year', '');
      dispatch(resetYears());
    }

    if (prevProps.loggedInUser.get('six_iron_fitting') !== this.props.loggedInUser.get('six_iron_fitting')) {
      setFieldValue('iron_distance', 145);
      setFieldValue('iron_spin_type', 'mid');
      setFieldValue('iron_launch_type', 'mid');
    }
  }
  segmentPlayerPost = () => {
    //const { dispatch, history, brands, models, years, formik, savedValues, loggedInUser } = this.props;
    //const { values, setFieldValue } = formik;
    const { formik } = this.props;
    const { values } = formik;
    var brand_post = "";
    if(values.brand === UNKNOWN_BRAND_STRING){
      brand_post = "Dont Know / It Varies";
    }
    else{
      brand_post = values.brand;
    }
    var codePost = null;
    if(this.state.zipCode){
      codePost = this.state.zipCode;
      window.analytics.identify(this.props.loggedInUser.get('id'),{
        ballnamicHandicap: values.handicap,
        playingPostalCode: this.state.zipCode
      });
    }
    else{
      window.analytics.identify(this.props.loggedInUser.get('id'),{
        ballnamicHandicap: values.handicap
      });
    }
    
    window.analytics.track("Player Profile", {
      promo: null,
      handicap: values.handicap,
      currentball: {
        brand: brand_post,
        model: values.model,
        year: values.year
      },
      playing_postal_code: codePost,
      fitting_id: null
    });
    window.gtag('event', 'Player Profile', {
      handicap: values.handicap,
      currentball: {
        brand: brand_post,
        model: values.model,
        year: values.year
      },
      playing_postal_code: codePost,
      fitting_id: null
    });
    window.gtag('event', 'Player Profile', {
      handicap: values.handicap,
      currentball: {
        brand: brand_post,
        model: values.model,
        year: values.year
      },
      playing_postal_code: codePost,
      fitting_id: null
    });
  }

  render() {
    const { dispatch, history, brands, models, years, formik, savedValues, loggedInUser } = this.props;
    const { values, setFieldValue } = formik;

    return (
      <div className="questionnaire__wrapper">
        <Helmet>
          <title>{`${strings.yourProfile} - ${strings.title}`}</title>
        </Helmet>
        <div className="questionnaire--inner">
          <div className="questionnaire--header">
            <h1>{strings.yourProfile}</h1>
            <h4>{strings.setUpYourProfile}</h4>
          </div>
          <h2>{strings.customerDetails}</h2>
          <div className="questionnaire-blocks__container">
            <MultiButtonField
              label={strings.handicap}
              choices={[{ label: strings.scratchOrBetter, value: 'scratch-or-better' }, '1-8', '9-15', '16+']}
              value={values.handicap}
              onChange={setFieldValue.bind(this, 'handicap')}
            />
            <div className="questionnaire--block">
              <h3>{strings.selectCurrentBall}</h3>
              <div className="questionnaire--block__inner">
                <Dropdown label={strings.brand} name="brand" options={brands} onChange={this.fetchModels} />
                <Dropdown
                  label={strings.model}
                  name="model"
                  options={models}
                  onChange={this.fetchYears.bind(this, values.brand)}
                />
                <Dropdown label={strings.year} name="year" options={years} />
              </div>
            </div>
            <AltitudeAndTemperature onZipcodeCalculated={this.handleZipcodeCalculated} />
          </div>
          <div className="questionnaire--button__wrapper next-only">
            <button
              className="button button--color--primary button--icon"
              type="button"
              onClick={async () => {
                const isValid = await validateProfileStep(formik);
                if (isValid) {
                  dispatch(setQuestionnaireValues(values));
                  // If both iron and driver are set to unknown, then we only need to calculate driver and it'll cascade
                  if (
                    !areLaunchConditionsKnown('driver', values, loggedInUser) &&
                    !areLaunchConditionsKnown('iron', values, loggedInUser)
                  ) {
                    calculateLaunchConditionsIfNeeded('driver', values, savedValues.toJS(), dispatch, loggedInUser);
                    this.segmentPlayerPost();
                  } else {
                    calculateLaunchConditionsIfNeeded('iron', values, savedValues.toJS(), dispatch, loggedInUser);
                    calculateLaunchConditionsIfNeeded('driver', values, savedValues.toJS(), dispatch, loggedInUser);
                    this.segmentPlayerPost();
                  }
                  history.push('/my-fitting/driver');
                }
              }}
            >
              <div>Next</div>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => {
    return {
      brands: state.questionnaire.brands,
      models: state.questionnaire.models,
      years: state.questionnaire.years,
      values: state.questionnaire.values,
      savedValues: state.questionnaire.values,
      loggedInUser: state.auth.loggedInUser,
    };
  }),
  formikConnect
)(YourProfile);
