import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';
import { fetchToken, fetchLoggedInUser } from './auth-actions';
import { triggerGTMEvent } from '../common/utils/gtm';

class PostLogin extends Component {
  componentDidMount() {
    //window.analytics.page();

    const { dispatch } = this.props;

    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const code = searchParams.get('code');

    dispatch(fetchToken(code));
  }

  componentDidUpdate(prevProps) {
    const { loggedInUser, fetchedToken, history, dispatch } = this.props;
    if (!prevProps.fetchedToken && fetchedToken) {
      dispatch(fetchLoggedInUser());
    }

    if (!prevProps.loggedInUser && loggedInUser) {
      if (loggedInUser.get('deleted_at')) {
        history.replace('/no-access');
      } else if (loggedInUser.get('type') === 'admin') {
        history.replace('/admin');
      } else {
        if (localStorage.getItem('giftBallFitting') === 'true') {
          localStorage.removeItem('giftBallFitting');
          history.replace('/gift');
        } else {
          if (loggedInUser.get('type') === 'player') {
            triggerGTMEvent('player_logged_in');
          } else if (loggedInUser.get('type') === 'fitter') {
            triggerGTMEvent('fitter_logged_in');
          }
          history.replace('/my-fitting');
        }
      }
    }
  }

  render() {
    return null;
  }
}

export default compose(
  connect(state => ({
    token: state.auth.token,
    fetchedToken: state.auth.fetchToken.get('loaded'),
    loggedInUser: state.auth.loggedInUser,
  })),
  withRouter
)(PostLogin);
