export default window.__config || {
  analytics: {
    measurementId: 'G-WCZFHPB9EP',
  },
  apiUrl: 'https://api.develop.ballfitting.com/',
  auth: {
    clientId: 'synapse1',
    redirectUri: 'https://develop.ballfitting.com/post-login',
    postLogoutRedirectUri: 'https://develop.ballfitting.com/post-logout',
    domain: 'https://auth.develop.ballfitting.com',
    scope: 'openid email offline_access',
    responseType: 'code',
  },
  contentful: {
    space: '74igzcw7kfyq',
    accessToken: 'w6O3TuLe6kqHVBNDPHhv3Aa2UVvE37P1HNJzzdVnLPU',
    previewToken: '0_zVEKQZafrFhjxnFg3bxDLLMCRPZadYJwXX0jB3V_c',
    environment: 'dev',
  },
  sentry: {
    dsn: 'https://eca0cce638844acdbb6baf1f3b513d56@o451520.ingest.sentry.io/5437728',
  },
  gtm: {
    id: 'GTM-NRDFVNR',
    auth: 'e_JMRBZ4BkLbZv9LoHBihg',
    preview: 'env-8',
  },
  appDomain: 'develop.ballfitting.com',
  fileStorage: {
    driver: 'S3',
    cobrandingUploadContainer: 'dev-ballnamic-cobranding',
  },
  payPalEnabled: true,
  applePayEnabled: true,
  agentsUrl: 'https://dev-ballnamic-redirect-json-smesh.s3.us-west-2.amazonaws.com/agents.json',
};
