import React from 'react';
import LocalizedStrings from 'react-localization';
import ConfirmationModal from '../../../common/components/ConfirmationModal';

const strings = new LocalizedStrings({
  en: {
    maxResultsAlmostReached: 'Maximum Results Almost Reached',
    youHaveAlmostReachedTheMax: `You've almost reached the maximum number of results requests for your session. After this request you'll have 1 results request remaining. You can still interact with your latest results request but will be unable to fetch new results after your next attempt.`,
    getResults: 'Get Results',
  },
});

const MaxResultsAlmostReachedModal = ({ onClose, isOpen, onConfirm }) => {
  return (
    <ConfirmationModal
      isOpened={isOpen}
      content={strings.youHaveAlmostReachedTheMax}
      titleText={strings.maxResultsAlmostReached}
      buttonText={strings.getResults}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};

export default MaxResultsAlmostReachedModal;
