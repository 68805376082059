import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import NotFound from '../../NotFound';
import ProfileLayout from './ProfileLayout';
import MyProfile from './MyProfile';
import MyResults from './MyResults';
import BrandToggling from '../../common/components/BrandToggling';

class Profile extends Component {
  render() {
    return (
      <ProfileLayout>
        <Switch>
          <Route exact path="/profile" component={MyProfile} />
          <Route exact path="/profile/my-results" component={MyResults} />
          <Route exact path="/profile/settings" component={BrandToggling} />
          <Route path="*" component={NotFound} />
        </Switch>
      </ProfileLayout>
    );
  }
}

export default Profile;
