import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import { Carousel } from 'react-responsive-carousel';
import LandingPageHeader from './LandingPageHeader';
import Footer from '../common/components/Footer';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
// import GolfCartAnimated from '../common/components/icons/GolfCartAnimated';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';
import { fetchLoggedInUser } from '../auth/auth-actions';

import AboutYou from './images/how-it-works/aboutyou.png';
import ActionableResults from './images/how-it-works/actionableresults.png';
import IndependentTesting from './images/how-it-works/independenttesting.png';
// import LearnExplore from './images/how-it-works/learnexplore.png';
// import GolfComQuote from './images/golf-com-quote/golf-com-quote.png';
import BallComparisonOne from './images/ball-comparisons/ball-comparison-1.png';
import BallComparisonTwo from './images/ball-comparisons/ball-comparison-2.png';
import BallComparisonThree from './images/ball-comparisons/ball-comparison-3.png';
import BallComparisonFour from './images/ball-comparisons/ball-comparison-4.png';
import BallComparisonFive from './images/ball-comparisons/ball-comparison-5.png';
import BallComparisonSix from './images/ball-comparisons/ball-comparison-6.png';
import BallComparisonSeven from './images/ball-comparisons/ball-comparison-7.png';
import BallComparisonEight from './images/ball-comparisons/ball-comparison-8.png';
import BallComparisonNine from './images/ball-comparisons/ball-comparison-9.png';
import BallComparisonTen from './images/ball-comparisons/ball-comparison-10.png';

import DotPattern from '../common/components/icons/DotPattern';
import LoadingIndicator from '../common/components/LoadingIndicator';
import ScrollToTop from '../common/components/ScrollToTop';
import CTAFooter from '../common/components/CTAFooter';
import Announcement from './Announcement';
import shuffle from '../common/utils/shuffle';
// import { sendURLtoCookie } from './rakuten-actions';
import BagAndClubs from './images/whats-inside/BagAndClubs.png';
import BallAndTee from './images/whats-inside/BallAndTee.png';
import PerformanceChart from './images/whats-inside/PerformanceChart.png';

const strings = new LocalizedStrings({
  en: {
    login: 'Login',
    registration: 'Register',
    library: 'Library',
    hero: {
      title: 'A Golf Ball Fitting Experience{break}Like No Other',
      subText: 'Take guesswork out of the equation with the most innovative golf ball fitting experience in the game.',
      buttonText: 'Start My Fitting',
    },
    quoteOne: {
      text:
        '"The beauty of Ballnamic is in its ability to suggest the best ball for certain conditions. Having this kind of info at your fingertips before a round can be a game-changer."',
      provider: 'GOLF.com',
    },
    quoteTwo: {
      text:
        '"PING\'s Ballnamic app is an excellent deep dive to narrow the impossibly large field of golf balls and player types and preferences."',
      provider: 'Golf Digest',
    },
    quoteThree: {
      text:
        '"There really isn\'t anything like this on the market that is truly brand agnostic. I\'m impressed and pleased with the results and for that reason I now play a different ball."',
      provider: 'Independent Golf Reviews',
    },
    quoteFour: {
      text:
        '"What makes this platform even more genuine is that PING does not make golf balls, so their recommendations are unbiased and based on thousands of data points."',
      provider: 'Arccos',
    },
    whatsIncluded: {
      heading: "What's Included",
      subText: 'For only $39, gain access to detailed performance metrics for golf balls built for your game.',
    },
    whatsInsideTitle: `{0} Process`,
    whatsInsideTitleBold: "Ballnamic's",
    whatsInsideOne: {
      heading: 'Tell Us About Your Game',
      subText:
        "We'll guide you through the fitting process as you enter detailed information about your swing, feel preferences, and where you play.",
    },
    whatsInsideTwo: {
      heading: 'Swing Analysis',
      subText:
        "We'll run your inputs against our ball database that is powered by cutting-edge algorithms and thousands of test shots, which evaluate and estimate ball performance in various conditions.",
    },
    whatsInsideThree: {
      heading: 'Actionable Results',
      subText:
        'For $39, receive four ball recommendations with detailed performance insights on workability, wind stability, and spin generation, as well as flight model projections for full down range driver and iron trajectories.',
    },
    whatsInsideFour: {
      heading: 'Learn and Explore',
      subText:
        'Knowledge is power. Our extensive library section allows you to learn and explore the science of ball flight and myth busting concepts.',
    },
    howBallnamicWorksTitle: `How {0} Works`,
    howBallnamicWorksTitleBold: 'Ballnamic',
    howBallnamicWorksOne: {
      heading: 'Tell us about your game',
      subText: 'Create your account and Ballnamic will guide you through a detailed fitting.',
    },
    howBallnamicWorksTwo: {
      heading: 'Access results for $39',
      subText: 'Leverage the most innovative ball fitting algorithms in golf.',
    },
    howBallnamicWorksThree: {
      heading: 'Go out and play!',
      subText: 'Ballnamic is designed to identify balls to help you improve your performance.',
    },
    poweredBy: {
      heading: 'Powered by Tour-Level Technology',
      subText:
        "Ballnamic is built upon a database of over 60 ball models, and it's frequently being updated. In order to account for all playing conditions and launch angles, each ball undergoes hundreds of test shots with a state-of-the-art swing robot and a TrackMan Launch Monitor. The data is compiled and analyzed by PING's team of data scientists.",
    },
    customContact: 'Custom fitter inquiries can be submitted to {0}',
    copyright:
      '{0} Copyright 2020 PING Inc. All rights reserved. Ballnamic is a trademark of Karsten Manufacturing Corporation in the U.S. and other countries. All third-party trademarks are the property of their respective owners, and use of such trademarks does not imply any affiliation with or endorsement by them.',
  },
});
const VimeoEmbed = () => {
  const embedCode = {
    __html: `
    <div class="video-container"><iframe src="https://player.vimeo.com/video/928771863?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Ballnamic Ball Fitting Results Explained"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
    `,
  };

  return <div dangerouslySetInnerHTML={embedCode} />;
};

class LandingPage extends Component {
  componentDidMount() {
    // const currentURL = window.location.href;
    // if(currentURL.includes('ranMID')){
    // sendURLtoCookie(currentURL);
    // }
    window.analytics.page('Landing Page');
    const { dispatch, token } = this.props;
    if (token) {
      dispatch(fetchLoggedInUser());
    }
    console.log('Bossa Nova');
  }

  render() {
    const { loggedInUser, fetchingLoggedInUser } = this.props;

    if (fetchingLoggedInUser) {
      return <LoadingIndicator />;
    }

    const comparisons = [
      { BallComparison: BallComparisonOne, alt: 'Driver Distance. Ball Comparison: Titleist - Volvic' },
      { BallComparison: BallComparisonTwo, alt: '7-Iron Carry. Ball Comparison: KIRKLAND - Cut' },
      { BallComparison: BallComparisonThree, alt: 'Flier Prevention Score. Ball Comparison: MAXFLI - CallaWay' },
      { BallComparison: BallComparisonFour, alt: 'Driver Wind Score. Ball Comparison: Bridgestone - TaylorMade' },
      { BallComparison: BallComparisonFive, alt: 'Green Side Spin. Ball Comparison: Snell - OnCore' },
      { BallComparison: BallComparisonSix, alt: 'Full Wedge Spin Score. Ball Comparison: Srixon - Titleist' },
      { BallComparison: BallComparisonSeven, alt: '7-Iron Workability Score. Ball Comparison: Vice - MAXFLI' },
      { BallComparison: BallComparisonEight, alt: 'Driver Height. Ball Comparison: Srixon - CallaWay' },
      { BallComparison: BallComparisonNine, alt: 'Putter Feel. Ball Comparison: TaylorMade - Mizuno' },
      { BallComparison: BallComparisonTen, alt: '7-Iron Roll. Ball Comparison: Bridgestone - WilsonStaff' },
    ];

    const randomizedComparisons = shuffle(comparisons);

    return (
      <ScrollToTop>
        <div className="ballnamic--landing-page">
          <Announcement />
          <LandingPageHeader isLoggedIn={!!loggedInUser} loggedInUser={loggedInUser} />
          <div className="hero__wrapper">
            <div className="container landing-page">
              <div className="hero">
                <div className="hero-content">
                  <div className="content--left">
                    <div className="dot-pattern">
                      <DotPattern />
                    </div>
                    <h1>{strings.formatString(strings.hero.title, { break: <br /> })}</h1>
                    <p>{strings.hero.subText}</p>
                    <Link to={loggedInUser ? '/my-fitting' : '/registration'} className="button button--color--primary">
                      {strings.hero.buttonText}
                    </Link>
                  </div>
                  <div className="content--right">
                    <CarouselProvider
                      naturalSlideWidth={500}
                      naturalSlideHeight={300}
                      totalSlides={randomizedComparisons.length}
                      interval={5000}
                      isPlaying
                      infinite
                      dragEnabled={false}
                      hasMasterSpinner
                    >
                      <Slider aria-label="Ball Comparison Slider" aria-live="off">
                        {randomizedComparisons.map((SlideBalls, index) => (
                          <Slide index={index} key={`comparison-${index}`} aria-label={SlideBalls.alt}>
                            <Image hasMasterSpinner src={SlideBalls.BallComparison} alt={SlideBalls.alt} />
                          </Slide>
                        ))}
                      </Slider>
                    </CarouselProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="how-it-works">
            <div className="whats-included">
              <h1> {strings.whatsIncluded.heading}</h1>
              <p>{strings.whatsIncluded.subText}</p>
              <div className="images-row">
                <img src={BallAndTee} alt="Data Driven Ball Recommendations" />
                <img src={PerformanceChart} alt="Performance Insights" />
                <img src={BagAndClubs} alt="5 Fittings" />
              </div>
              <VimeoEmbed />
            </div>
            <div className="quote--carousel container landing-page">
              <Carousel
                showStatus={false}
                infiniteLoop
                showThumbs={false}
                swipeable
                dynamicHeight
                autoPlay
                interval={7000}
              >
                <div className="how-it-works--slide">
                  <div className="quote-slide--text">
                    <p>{strings.quoteOne.text}</p>
                    <h3>{strings.quoteOne.provider}</h3>
                  </div>
                </div>
                <div className="how-it-works--slide">
                  <div className="quote-slide--text">
                    <p>{strings.quoteTwo.text}</p>
                    <h3>{strings.quoteTwo.provider}</h3>
                  </div>
                </div>
                <div className="how-it-works--slide">
                  <div className="quote-slide--text">
                    <p>{strings.quoteThree.text}</p>
                    <h3>{strings.quoteThree.provider}</h3>
                  </div>
                </div>
                <div className="how-it-works--slide">
                  <div className="quote-slide--text">
                    <p>{strings.quoteFour.text}</p>
                    <h3>{strings.quoteFour.provider}</h3>
                  </div>
                </div>
              </Carousel>
            </div>
            <div className="container landing-page">
              <h1>{strings.formatString(strings.whatsInsideTitle, <strong>{strings.whatsInsideTitleBold}</strong>)}</h1>
            </div>

            <div className="how-it-works--carousel container landing-page">
              <Carousel showStatus={false} infiniteLoop showThumbs={false} swipeable dynamicHeight>
                <div className="how-it-works--slide">
                  <div className="slide-info--text">
                    <h2>{strings.whatsInsideOne.heading}</h2>
                    <p>{strings.whatsInsideOne.subText}</p>
                  </div>
                  <img src={AboutYou} alt="img" />
                </div>
                <div className="how-it-works--slide">
                  <div className="slide-info--text">
                    <h2>{strings.whatsInsideTwo.heading}</h2>
                    <p>{strings.whatsInsideTwo.subText}</p>
                  </div>
                  <img src={IndependentTesting} alt="img" />
                </div>
                <div className="how-it-works--slide">
                  <div className="slide-info--text">
                    <h2>{strings.whatsInsideThree.heading}</h2>
                    <p>{strings.whatsInsideThree.subText}</p>
                  </div>
                  <img src={ActionableResults} alt="img" />
                </div>
                {/* <div className="how-it-works--slide">
                  <div className="slide-info--text">
                    <h2>{strings.whatsInsideFour.heading}</h2>
                    <p>{strings.whatsInsideFour.subText}</p>
                  </div>
                  <img src={LearnExplore} alt="img" />
                </div> */}
              </Carousel>
            </div>
          </div>
          <div className="powered-by">
            <h2>{strings.poweredBy.heading}</h2>
            <p>{strings.poweredBy.subText}</p>
            <a href="https://ballfitting.com/library/articles/testing-deep-dive">Learn More</a>
          </div>
          {/* <div className="golf-com-quote--section">
            <img src={GolfComQuote} alt="Golf.com Quote"/>
          </div> */}

          {/* <div className="golf-cart--section">
            <div className="container landing-page">
              <h1>
                {strings.formatString(
                  strings.howBallnamicWorksTitle,
                  <strong>{strings.howBallnamicWorksTitleBold}</strong>
                )}
              </h1>
            </div>
            <div className="container landing-page">
              <div className="golf-cart-trajectory">
                <div className="golf-cart">
                  <GolfCartAnimated />
                </div>
              </div>
              <div className="golf-cart-grid">
                <div className="circle">
                  <div className="small-circle" />
                </div>
                <div className="path"></div>
                <div className="circle">
                  <div className="small-circle" />
                </div>
                <div className="path"></div>
                <div className="circle">
                  <div className="small-circle" />
                </div>
              </div>
              <div className="golf-cart-text-points">
                <div className="point point-1">
                  <h4>{strings.howBallnamicWorksOne.heading}</h4>
                  <p>{strings.howBallnamicWorksOne.subText}</p>
                </div>
                <div className="point point-2">
                  <h4>{strings.howBallnamicWorksTwo.heading}</h4>
                  <p>{strings.howBallnamicWorksTwo.subText}</p>
                </div>
                <div className="point point-3">
                  <h4>{strings.howBallnamicWorksThree.heading}</h4>
                  <p>{strings.howBallnamicWorksThree.subText}</p>
                </div>
              </div>
            </div> */
          /* </div> */}
          <CTAFooter />
          <Footer />
        </div>
      </ScrollToTop>
    );
  }
}

export default compose(
  connect(state => ({
    token: state.auth.token,
    loggedInUser: state.auth.loggedInUser,
    fetchingLoggedInUser: state.auth.fetchLoggedInUser.get('loading'),
  }))
)(LandingPage);
