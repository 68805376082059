import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClearIcon from '../common/components/icons/Clear';
import { NavLink } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import MenuIcon from '../common/components/icons/Menu';

let strings = new LocalizedStrings({
  en: {
    myFitting: 'My Fitting',
    library: 'Library',
    close: 'Close',
  },
});

class Sidebar extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    activeStyle: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    buttonAriaLabel: PropTypes.string.isRequired,
    questionnaireIsActive: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.addEventListener('focusin', this.handleFocusIn);
    document.addEventListener('mousedown', this.handleDomClick);
  }

  componentWillUnmount() {
    document.removeEventListener('focusin', this.handleFocusIn);
    document.removeEventListener('mousedown', this.handleDomClick);
  }

  handleFocusIn = () => {
    // Hide sidebar if tabbed off of it
    if (this.ref !== null && !this.ref.contains(document.activeElement)) {
      this.props.onClose();
    }
  };

  handleDomClick = e => {
    if (!this.ref.contains(e.target) && this.props.isOpen) {
      this.props.onClose();
    }
  };

  render() {
    const { isOpen, onClose, onOpen, activeStyle, buttonAriaLabel, questionnaireIsActive, location } = this.props;
    const sidebarClasses = {
      sidebar: true,
      'sidebar--open': isOpen,
    };
    const sidebarHeaderClasses = {
      'sidebar--header': true,
      'sidebar--header--open': isOpen,
    };
    const mobileOverlayClasses = {
      'mobile-overlay': true,
      'mobile-overlay--visible': isOpen,
    };
    const sidebarMenuClasses = {
      'sidebar-menu': true,
      'sidebar-menu--visible': isOpen,
    };

    return (
      <>
        <button type="button" onClick={onOpen} aria-label={buttonAriaLabel}>
          <MenuIcon fill="#616A70" />
        </button>
        <div className={classNames(sidebarClasses)} ref={node => (this.ref = node)}>
          <div className={classNames(sidebarHeaderClasses)}>
            <button type="button" onClick={onClose} ref={this.closeButton} aria-label={strings.close}>
              <ClearIcon fill="#616A70" />
            </button>
          </div>
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink
              className="user-nav__links--link"
              to={{
                pathname: location.state?.returnPath || '/my-fitting/get-started',
              }}
              activeStyle={activeStyle}
              isActive={questionnaireIsActive}
            >
              {strings.myFitting}
            </NavLink>
            <NavLink
              className="user-nav__links--link"
              to={{
                pathname: '/library',
                state: {
                  returnPath: location.pathname.startsWith('/my-fitting')
                    ? location.pathname
                    : location.state?.returnPath,
                },
              }}
              activeStyle={activeStyle}
            >
              {strings.library}
            </NavLink>
          </div>
        </div>
        <div className={classNames(mobileOverlayClasses)} onClick={this.toggleSidebar} />
      </>
    );
  }
}

export default withRouter(Sidebar);
