import localstorage from 'store2';

import { getPromoCode } from '../../payment/payment-actions';

export const trackPromoCode = (location, dispatch, loggedInUser) => {
  const params = new URLSearchParams(location.search);

  let code = params.get('p');
  const now = new Date();

  if (!code) {
    let storedCode = localstorage.get('promoCode');

    if (storedCode) {
      storedCode = JSON.parse(storedCode);

      if (storedCode.expiresOn > now.getTime()) {
        code = storedCode.code;
      } else {
        localstorage.remove('promoCode');
      }
    }
  }

  if (code) {
    now.setDate(now.getDate() + 7);
    localstorage.set('promoCode', JSON.stringify({ code, expiresOn: now.getTime() }));

    if (loggedInUser) {
      dispatch(getPromoCode(code));
    }
  }
};
