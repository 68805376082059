import React from 'react';
import Svg, { Path } from 'svgs';

const Power = ({ width, height, fill }) => {
  return (
    <Svg
      height={height || 24}
      width={width || 24}
      viewBox="0 0 14 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M10 3.182v1.49c0 .276.182.485.385.672A4.956 4.956 0 0112 9c0 2.757-2.243 5-5 5s-5-2.243-5-5c0-1.448.626-2.745 1.615-3.656.203-.187.385-.38.385-.656V3.182c0-.276-.204-.405-.444-.269A6.987 6.987 0 000 9a7 7 0 0014 0 6.987 6.987 0 00-3.556-6.087c-.24-.137-.444-.008-.444.269z" />
      <Path d="M7 7a1 1 0 001-1V1a1 1 0 10-2 0v5a1 1 0 001 1z" />
    </Svg>
  );
};

export default Power;
