import React from 'react';
import Svg, { Path } from 'svgs';

const ChevronLeft = ({ size, fill }) => {
  return (
    <Svg height={size || 92} width={size || 92} viewBox="0 0 92 92" xmlns="http://www.w3.org/2000/svg">
      <Path fill={fill || '#5057FF'} d="M2 1a1 1 0 11-2 0 1 1 0 012 0z" />
      <Path
        fill={fill || '#5057FF'}
        d="M2 1a1 1 0 11-2 0 1 1 0 012 0zM2 7a1 1 0 11-2 0 1 1 0 012 0zM2 13a1 1 0 11-2 0 1 1 0 012 0zM2 19a1 1 0 11-2 0 1 1 0 012 0zM2 25a1 1 0 11-2 0 1 1 0 012 0zM2 31a1 1 0 11-2 0 1 1 0 012 0zM2 37a1 1 0 11-2 0 1 1 0 012 0zM2 43a1 1 0 11-2 0 1 1 0 012 0zM2 49a1 1 0 11-2 0 1 1 0 012 0zM2 55a1 1 0 11-2 0 1 1 0 012 0zM2 61a1 1 0 11-2 0 1 1 0 012 0zM2 67a1 1 0 11-2 0 1 1 0 012 0zM2 73a1 1 0 11-2 0 1 1 0 012 0zM2 79a1 1 0 11-2 0 1 1 0 012 0zM2 85a1 1 0 11-2 0 1 1 0 012 0zM2 91a1 1 0 11-2 0 1 1 0 012 0zM8 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M8 1a1 1 0 11-2 0 1 1 0 012 0zM8 7a1 1 0 11-2 0 1 1 0 012 0zM8 13a1 1 0 11-2 0 1 1 0 012 0zM8 19a1 1 0 11-2 0 1 1 0 012 0zM8 25a1 1 0 11-2 0 1 1 0 012 0zM8 31a1 1 0 11-2 0 1 1 0 012 0zM8 37a1 1 0 11-2 0 1 1 0 012 0zM8 43a1 1 0 11-2 0 1 1 0 012 0zM8 49a1 1 0 11-2 0 1 1 0 012 0zM8 55a1 1 0 11-2 0 1 1 0 012 0zM8 61a1 1 0 11-2 0 1 1 0 012 0zM8 67a1 1 0 11-2 0 1 1 0 012 0zM8 73a1 1 0 11-2 0 1 1 0 012 0zM8 79a1 1 0 11-2 0 1 1 0 012 0zM8 85a1 1 0 11-2 0 1 1 0 012 0zM8 91a1 1 0 11-2 0 1 1 0 012 0zM14 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M14 1a1 1 0 11-2 0 1 1 0 012 0zM14 7a1 1 0 11-2 0 1 1 0 012 0zM14 13a1 1 0 11-2 0 1 1 0 012 0zM14 19a1 1 0 11-2 0 1 1 0 012 0zM14 25a1 1 0 11-2 0 1 1 0 012 0zM14 31a1 1 0 11-2 0 1 1 0 012 0zM14 37a1 1 0 11-2 0 1 1 0 012 0zM14 43a1 1 0 11-2 0 1 1 0 012 0zM14 49a1 1 0 11-2 0 1 1 0 012 0zM14 55a1 1 0 11-2 0 1 1 0 012 0zM14 61a1 1 0 11-2 0 1 1 0 012 0zM14 67a1 1 0 11-2 0 1 1 0 012 0zM14 73a1 1 0 11-2 0 1 1 0 012 0zM14 79a1 1 0 11-2 0 1 1 0 012 0zM14 85a1 1 0 11-2 0 1 1 0 012 0zM14 91a1 1 0 11-2 0 1 1 0 012 0zM20 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M20 1a1 1 0 11-2 0 1 1 0 012 0zM20 7a1 1 0 11-2 0 1 1 0 012 0zM20 13a1 1 0 11-2 0 1 1 0 012 0zM20 19a1 1 0 11-2 0 1 1 0 012 0zM20 25a1 1 0 11-2 0 1 1 0 012 0zM20 31a1 1 0 11-2 0 1 1 0 012 0zM20 37a1 1 0 11-2 0 1 1 0 012 0zM20 43a1 1 0 11-2 0 1 1 0 012 0zM20 49a1 1 0 11-2 0 1 1 0 012 0zM20 55a1 1 0 11-2 0 1 1 0 012 0zM20 61a1 1 0 11-2 0 1 1 0 012 0zM20 67a1 1 0 11-2 0 1 1 0 012 0zM20 73a1 1 0 11-2 0 1 1 0 012 0zM20 79a1 1 0 11-2 0 1 1 0 012 0zM20 85a1 1 0 11-2 0 1 1 0 012 0zM20 91a1 1 0 11-2 0 1 1 0 012 0zM26 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M26 1a1 1 0 11-2 0 1 1 0 012 0zM26 7a1 1 0 11-2 0 1 1 0 012 0zM26 13a1 1 0 11-2 0 1 1 0 012 0zM26 19a1 1 0 11-2 0 1 1 0 012 0zM26 25a1 1 0 11-2 0 1 1 0 012 0zM26 31a1 1 0 11-2 0 1 1 0 012 0zM26 37a1 1 0 11-2 0 1 1 0 012 0zM26 43a1 1 0 11-2 0 1 1 0 012 0zM26 49a1 1 0 11-2 0 1 1 0 012 0zM26 55a1 1 0 11-2 0 1 1 0 012 0zM26 61a1 1 0 11-2 0 1 1 0 012 0zM26 67a1 1 0 11-2 0 1 1 0 012 0zM26 73a1 1 0 11-2 0 1 1 0 012 0zM26 79a1 1 0 11-2 0 1 1 0 012 0zM26 85a1 1 0 11-2 0 1 1 0 012 0zM26 91a1 1 0 11-2 0 1 1 0 012 0zM32 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M32 1a1 1 0 11-2 0 1 1 0 012 0zM32 7a1 1 0 11-2 0 1 1 0 012 0zM32 13a1 1 0 11-2 0 1 1 0 012 0zM32 19a1 1 0 11-2 0 1 1 0 012 0zM32 25a1 1 0 11-2 0 1 1 0 012 0zM32 31a1 1 0 11-2 0 1 1 0 012 0zM32 37a1 1 0 11-2 0 1 1 0 012 0zM32 43a1 1 0 11-2 0 1 1 0 012 0zM32 49a1 1 0 11-2 0 1 1 0 012 0zM32 55a1 1 0 11-2 0 1 1 0 012 0zM32 61a1 1 0 11-2 0 1 1 0 012 0zM32 67a1 1 0 11-2 0 1 1 0 012 0zM32 73a1 1 0 11-2 0 1 1 0 012 0zM32 79a1 1 0 11-2 0 1 1 0 012 0zM32 85a1 1 0 11-2 0 1 1 0 012 0zM32 91a1 1 0 11-2 0 1 1 0 012 0zM38 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M38 1a1 1 0 11-2 0 1 1 0 012 0zM38 7a1 1 0 11-2 0 1 1 0 012 0zM38 13a1 1 0 11-2 0 1 1 0 012 0zM38 19a1 1 0 11-2 0 1 1 0 012 0zM38 25a1 1 0 11-2 0 1 1 0 012 0zM38 31a1 1 0 11-2 0 1 1 0 012 0zM38 37a1 1 0 11-2 0 1 1 0 012 0zM38 43a1 1 0 11-2 0 1 1 0 012 0zM38 49a1 1 0 11-2 0 1 1 0 012 0zM38 55a1 1 0 11-2 0 1 1 0 012 0zM38 61a1 1 0 11-2 0 1 1 0 012 0zM38 67a1 1 0 11-2 0 1 1 0 012 0zM38 73a1 1 0 11-2 0 1 1 0 012 0zM38 79a1 1 0 11-2 0 1 1 0 012 0zM38 85a1 1 0 11-2 0 1 1 0 012 0zM38 91a1 1 0 11-2 0 1 1 0 012 0zM44 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M44 1a1 1 0 11-2 0 1 1 0 012 0zM44 7a1 1 0 11-2 0 1 1 0 012 0zM44 13a1 1 0 11-2 0 1 1 0 012 0zM44 19a1 1 0 11-2 0 1 1 0 012 0zM44 25a1 1 0 11-2 0 1 1 0 012 0zM44 31a1 1 0 11-2 0 1 1 0 012 0zM44 37a1 1 0 11-2 0 1 1 0 012 0zM44 43a1 1 0 11-2 0 1 1 0 012 0zM44 49a1 1 0 11-2 0 1 1 0 012 0zM44 55a1 1 0 11-2 0 1 1 0 012 0zM44 61a1 1 0 11-2 0 1 1 0 012 0zM44 67a1 1 0 11-2 0 1 1 0 012 0zM44 73a1 1 0 11-2 0 1 1 0 012 0zM44 79a1 1 0 11-2 0 1 1 0 012 0zM44 85a1 1 0 11-2 0 1 1 0 012 0zM44 91a1 1 0 11-2 0 1 1 0 012 0zM50 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M50 1a1 1 0 11-2 0 1 1 0 012 0zM50 7a1 1 0 11-2 0 1 1 0 012 0zM50 13a1 1 0 11-2 0 1 1 0 012 0zM50 19a1 1 0 11-2 0 1 1 0 012 0zM50 25a1 1 0 11-2 0 1 1 0 012 0zM50 31a1 1 0 11-2 0 1 1 0 012 0zM50 37a1 1 0 11-2 0 1 1 0 012 0zM50 43a1 1 0 11-2 0 1 1 0 012 0zM50 49a1 1 0 11-2 0 1 1 0 012 0zM50 55a1 1 0 11-2 0 1 1 0 012 0zM50 61a1 1 0 11-2 0 1 1 0 012 0zM50 67a1 1 0 11-2 0 1 1 0 012 0zM50 73a1 1 0 11-2 0 1 1 0 012 0zM50 79a1 1 0 11-2 0 1 1 0 012 0zM50 85a1 1 0 11-2 0 1 1 0 012 0zM50 91a1 1 0 11-2 0 1 1 0 012 0zM56 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M56 1a1 1 0 11-2 0 1 1 0 012 0zM56 7a1 1 0 11-2 0 1 1 0 012 0zM56 13a1 1 0 11-2 0 1 1 0 012 0zM56 19a1 1 0 11-2 0 1 1 0 012 0zM56 25a1 1 0 11-2 0 1 1 0 012 0zM56 31a1 1 0 11-2 0 1 1 0 012 0zM56 37a1 1 0 11-2 0 1 1 0 012 0zM56 43a1 1 0 11-2 0 1 1 0 012 0zM56 49a1 1 0 11-2 0 1 1 0 012 0zM56 55a1 1 0 11-2 0 1 1 0 012 0zM56 61a1 1 0 11-2 0 1 1 0 012 0zM56 67a1 1 0 11-2 0 1 1 0 012 0zM56 73a1 1 0 11-2 0 1 1 0 012 0zM56 79a1 1 0 11-2 0 1 1 0 012 0zM56 85a1 1 0 11-2 0 1 1 0 012 0zM56 91a1 1 0 11-2 0 1 1 0 012 0zM62 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M62 1a1 1 0 11-2 0 1 1 0 012 0zM62 7a1 1 0 11-2 0 1 1 0 012 0zM62 13a1 1 0 11-2 0 1 1 0 012 0zM62 19a1 1 0 11-2 0 1 1 0 012 0zM62 25a1 1 0 11-2 0 1 1 0 012 0zM62 31a1 1 0 11-2 0 1 1 0 012 0zM62 37a1 1 0 11-2 0 1 1 0 012 0zM62 43a1 1 0 11-2 0 1 1 0 012 0zM62 49a1 1 0 11-2 0 1 1 0 012 0zM62 55a1 1 0 11-2 0 1 1 0 012 0zM62 61a1 1 0 11-2 0 1 1 0 012 0zM62 67a1 1 0 11-2 0 1 1 0 012 0zM62 73a1 1 0 11-2 0 1 1 0 012 0zM62 79a1 1 0 11-2 0 1 1 0 012 0zM62 85a1 1 0 11-2 0 1 1 0 012 0zM62 91a1 1 0 11-2 0 1 1 0 012 0zM68 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M68 1a1 1 0 11-2 0 1 1 0 012 0zM68 7a1 1 0 11-2 0 1 1 0 012 0zM68 13a1 1 0 11-2 0 1 1 0 012 0zM68 19a1 1 0 11-2 0 1 1 0 012 0zM68 25a1 1 0 11-2 0 1 1 0 012 0zM68 31a1 1 0 11-2 0 1 1 0 012 0zM68 37a1 1 0 11-2 0 1 1 0 012 0zM68 43a1 1 0 11-2 0 1 1 0 012 0zM68 49a1 1 0 11-2 0 1 1 0 012 0zM68 55a1 1 0 11-2 0 1 1 0 012 0zM68 61a1 1 0 11-2 0 1 1 0 012 0zM68 67a1 1 0 11-2 0 1 1 0 012 0zM68 73a1 1 0 11-2 0 1 1 0 012 0zM68 79a1 1 0 11-2 0 1 1 0 012 0zM68 85a1 1 0 11-2 0 1 1 0 012 0zM68 91a1 1 0 11-2 0 1 1 0 012 0zM74 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M74 1a1 1 0 11-2 0 1 1 0 012 0zM74 7a1 1 0 11-2 0 1 1 0 012 0zM74 13a1 1 0 11-2 0 1 1 0 012 0zM74 19a1 1 0 11-2 0 1 1 0 012 0zM74 25a1 1 0 11-2 0 1 1 0 012 0zM74 31a1 1 0 11-2 0 1 1 0 012 0zM74 37a1 1 0 11-2 0 1 1 0 012 0zM74 43a1 1 0 11-2 0 1 1 0 012 0zM74 49a1 1 0 11-2 0 1 1 0 012 0zM74 55a1 1 0 11-2 0 1 1 0 012 0zM74 61a1 1 0 11-2 0 1 1 0 012 0zM74 67a1 1 0 11-2 0 1 1 0 012 0zM74 73a1 1 0 11-2 0 1 1 0 012 0zM74 79a1 1 0 11-2 0 1 1 0 012 0zM74 85a1 1 0 11-2 0 1 1 0 012 0zM74 91a1 1 0 11-2 0 1 1 0 012 0zM80 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M80 1a1 1 0 11-2 0 1 1 0 012 0zM80 7a1 1 0 11-2 0 1 1 0 012 0zM80 13a1 1 0 11-2 0 1 1 0 012 0zM80 19a1 1 0 11-2 0 1 1 0 012 0zM80 25a1 1 0 11-2 0 1 1 0 012 0zM80 31a1 1 0 11-2 0 1 1 0 012 0zM80 37a1 1 0 11-2 0 1 1 0 012 0zM80 43a1 1 0 11-2 0 1 1 0 012 0zM80 49a1 1 0 11-2 0 1 1 0 012 0zM80 55a1 1 0 11-2 0 1 1 0 012 0zM80 61a1 1 0 11-2 0 1 1 0 012 0zM80 67a1 1 0 11-2 0 1 1 0 012 0zM80 73a1 1 0 11-2 0 1 1 0 012 0zM80 79a1 1 0 11-2 0 1 1 0 012 0zM80 85a1 1 0 11-2 0 1 1 0 012 0zM80 91a1 1 0 11-2 0 1 1 0 012 0zM86 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M86 1a1 1 0 11-2 0 1 1 0 012 0zM86 7a1 1 0 11-2 0 1 1 0 012 0zM86 13a1 1 0 11-2 0 1 1 0 012 0zM86 19a1 1 0 11-2 0 1 1 0 012 0zM86 25a1 1 0 11-2 0 1 1 0 012 0zM86 31a1 1 0 11-2 0 1 1 0 012 0zM86 37a1 1 0 11-2 0 1 1 0 012 0zM86 43a1 1 0 11-2 0 1 1 0 012 0zM86 49a1 1 0 11-2 0 1 1 0 012 0zM86 55a1 1 0 11-2 0 1 1 0 012 0zM86 61a1 1 0 11-2 0 1 1 0 012 0zM86 67a1 1 0 11-2 0 1 1 0 012 0zM86 73a1 1 0 11-2 0 1 1 0 012 0zM86 79a1 1 0 11-2 0 1 1 0 012 0zM86 85a1 1 0 11-2 0 1 1 0 012 0zM86 91a1 1 0 11-2 0 1 1 0 012 0zM92 1a1 1 0 11-2 0 1 1 0 012 0z"
      />
      <Path
        fill={fill || '#5057FF'}
        d="M92 1a1 1 0 11-2 0 1 1 0 012 0zM92 7a1 1 0 11-2 0 1 1 0 012 0zM92 13a1 1 0 11-2 0 1 1 0 012 0zM92 19a1 1 0 11-2 0 1 1 0 012 0zM92 25a1 1 0 11-2 0 1 1 0 012 0zM92 31a1 1 0 11-2 0 1 1 0 012 0zM92 37a1 1 0 11-2 0 1 1 0 012 0zM92 43a1 1 0 11-2 0 1 1 0 012 0zM92 49a1 1 0 11-2 0 1 1 0 012 0zM92 55a1 1 0 11-2 0 1 1 0 012 0zM92 61a1 1 0 11-2 0 1 1 0 012 0zM92 67a1 1 0 11-2 0 1 1 0 012 0zM92 73a1 1 0 11-2 0 1 1 0 012 0zM92 79a1 1 0 11-2 0 1 1 0 012 0zM92 85a1 1 0 11-2 0 1 1 0 012 0zM92 91a1 1 0 11-2 0 1 1 0 012 0z"
      />
    </Svg>
  );
};

export default ChevronLeft;
