import React from 'react';
import { Helmet } from 'react-helmet';
import LocalizedStrings from 'react-localization';

import CTAFooter from '../common/components/CTAFooter';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'Affiliate',
    header: 'Become a Ballnamic Affiliate',
    headerHelperText: 'Here are our most asked questions.',
  },
});

const programDetails = [
  {
    question: 'What does it cost to become a Ballnamic affiliate?',
    answer: `Applying and joining the Ballnamic Affiliate Program is free. There are no fees, charges to apply, or
    minimum sales requirement.`,
  },
  {
    question: `Where do I get links to promote Ballnamic?`,
    answer: `Log into your Rakuten account, go to Links and click Get Links, hover over PING and select which type of link you would like (banner or text), once selected publishers will be able to copy and paste their link.`,
  },
  {
    question: `Can I take orders on my website?`,
    answer: `No. Affiliate-referred transactions and sales must take place on Ballfitting.com`,
  },
  {
    question: `Can I use Ballnamic logos and product names on my website?`,
    answer: `You are allowed to use approved logos and creative assets provided through the affiliate program.`,
  },
  {
    question: `Who can I contact about becoming a Ballnamic affiliate partner?`,
    answer: `If you have any additional questions or need help getting started, please reach out to the affiliate team! Ballnamic has partnered with iAffiliate Management to manage and grow the affiliate partnerships. Please email the Ballnamic account team at ping@iaffiliatemanagement.com if you need any assistance.`,
  },
  {
    question: `Does Ballnamic accept all affiliate applications?`,
    answer: `Not all affiliate applications will be accepted. Ballnamic evaluates all potential affiliates who apply to ensure that the applicant's reputation, credibility and content are relevant to the golf industry and align with Ballnamic's brand values, target audience, and product offerings.`,
  },
];

const trackingAndPayments = [
  {
    question: `How can I track how many sales I’ve referred?`,
    answer: `Ballnamic has partnered with a 3rd party network for all tracking, reporting, and payments. After being approved for the Ballnamic affiliate program, you’ll have access to reporting on the network that will provide you detailed analytics for referred traffic, sales, and commissions earned.`,
  },
  {
    question: `When will I get paid?`,
    answer: `You will be paid approximately 60 days after a commissionable sale is completed. This time allows for returns and adjustments to be made.`,
  },
];

const makeBold = (item, keyword) => {
  var re = new RegExp(keyword, 'g');
  return item.replace(re, `<a href="https://${keyword}">` + keyword + '</a>');
};

function Affiliate() {
  window.analytics.page("Affiliate Marketing Program");
  return (
    <>
      <div className="container landing-page">
        <Helmet>
          <title>{`${strings.page} - ${strings.title}`}</title>
        </Helmet>

        <div className="container library">
          <div className="faq-header">
            <h1 style={{ fontFamily: 'Helvetica Neue, sans-serif' }}>{strings.header}</h1>
          </div>
          <div className="affiliate-item ">
            <h2 className="affiliate-text">How does the affiliate program work?</h2>
            <p className="affiliate-helper-text">
              {' '}
              The Ballnamic Affiliate Program allows you to earn money by referring customers to the Ballnamic website.
              Any time you refer a customer to our website and that customer makes a purchase, you earn a commission (a
              percentage of each sale). Ballnamic handles the transaction processing, fulfilment, and customer service.
            </p>
          </div>

          <div className="affiliate-item ">
            <h2 className="affiliate-text">How do I get started?</h2>
            <p className="affiliate-helper-text">
              <ul>
                <li>1. Start by registering for an account with Rakuten (our 3rd party affiliate vendor).</li>
                <li>2. Apply to the Ballnamic affiliate program by completing the Rakuten application process.</li>
                <li>3. Get accepted into the Ballnamic affiliate program.</li>
                <li>4. Get your affiliate links from the link section of the network.</li>
                <li>
                5. Promote Ballnamic on your website, social media accounts, etc
                </li>
                <li>6. Log into the network to view your performance reports: see how much traffic you’ve referred, referred sales, and commissions earned. Reports are updated every day.</li>
                <li>7. Get paid your earned commission!</li>
              </ul>
            </p>
          </div>
          <div className="affiliate-item  affiliate-item-line" style={{ paddingBottom: '80px', paddingTop: '35px' }}>
            <button
              className="button button--color--primary"
              type="button"
              onClick={() => {
                window.location.href = 'https://signup.linkshare.com/publishers/registration/landing?mid=52860';
              }}
            >
              Register Now
            </button>
            <br></br><br></br><br></br>
            <a class="link" href="https://pingmediastage.azureedge.net/mediastorage/mediastorage/ping-na/medialibrary/misc/affiliate-program/ping_affiliate_program_terms.pdf">Ballnamic's Affiliate Marketing Agreement</a>
            <span></span>
          </div>
          <div className="affiliate-item " style={{ paddingTop: '30px' }}>
            <div className="faq-items--list">
              <h2 className="affiliate-text">Program Details</h2>
              {programDetails.length > 0
                ? programDetails.map(item => {
                    return (
                      <div className="faq-item">
                        <h2 style={{ fontSize: '25px' }}>{item.question}</h2>
                        <p
                          className="affiliate-helper-text"
                          dangerouslySetInnerHTML={{ __html: makeBold(item.answer, 'Ballfitting.com') }}
                        ></p>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>

          <div className="affiliate-item " style={{ paddingTop: '0px' }}>
            <div className="faq-items--list">
              <h2 className="affiliate-text">Tracking, Referred Sales, and Payment</h2>
              {trackingAndPayments.length > 0
                ? trackingAndPayments.map(item => {
                    return (
                      <div className="faq-item">
                        <h2 style={{ fontSize: '25px' }}>{item.question}</h2>
                        <p
                          className="affiliate-helper-text"
                          dangerouslySetInnerHTML={{ __html: makeBold(item.answer, 'Ballnamic.com') }}
                        ></p>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>
          <div className="affiliate-item " style={{ paddingTop: '0px', paddingBottom: '80px' }}>
            <h2 className="affiliate-text" style={{ fontWeight: '600' }}>
              Questions
            </h2>
            <p className="affiliate-helper-text">
              {' '}
              Questions about our affiliate program can be sent to ping@iaffiliatemanagement.com
            </p>
          </div>
        </div>
      </div>
      <CTAFooter />
    </>
  );
}

export default Affiliate;
