import { combineReducers } from 'redux';
import Immutable from 'immutable';
import getApiReducer from '../common/utils/getApiReducer';
import { FETCH_LOGGED_IN_USER, FETCH_TOKEN, UPDATE_TOKEN, REGISTER } from './auth-action-constants';
import { UPDATE_USER_SETTINGS, UPDATE_USER_PROFILE } from '../user/user-action-constants';
import { SUBMIT_RESULTS_PAYMENT } from '../payment/payment-action-constants';
import { FETCH_RESULTS } from '../fitting/Questionnaire/questionnaire-action-constants';
import { logout } from './auth-redirects';
import localstorage from 'store2';

export default combineReducers({
  fetchToken: getApiReducer(FETCH_TOKEN),
  fetchLoggedInUser: getApiReducer(FETCH_LOGGED_IN_USER),
  register: getApiReducer(REGISTER),
  loggedInUser: (state = null, action) => {
    if (action.type === `${FETCH_LOGGED_IN_USER}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    if (action.type === `${SUBMIT_RESULTS_PAYMENT}_SUCCESS`) {
      return state.merge(action.json);
    }
    if (action.type === `${UPDATE_USER_SETTINGS}_SUCCESS` && state.get('id') === action.json.id) {
      return state.merge(action.json);
    }
    if (action.type === `${UPDATE_USER_PROFILE}_SUCCESS`) {
      return state.merge(action.json);
    }
    if (action.type === `${FETCH_RESULTS}_SUCCESS` && typeof action.json.results_remaining !== 'undefined') {
      return state.merge({
        results_remaining: action.json.results_remaining,
        results_requested: action.json.results_requested,
      });
    }
    return state;
  },
  token: (state = localstorage.get('idToken') || null, action) => {
    if (action.type === `${FETCH_TOKEN}_REQUEST`) {
      return null;
    }
    if (action.type === `${FETCH_TOKEN}_SUCCESS` || action.type === `${REGISTER}_SUCCESS`) {
      let decoded = JSON.parse(atob(action.json.id_token.split('.')[1]));
      if (action.type === `${REGISTER}_SUCCESS` || decoded.nonce === localstorage.get('nonce')) {
        localstorage.remove('nonce');
        localstorage.remove('code_verifier');
        localstorage.set('idToken', action.json.id_token);
        localstorage.set('refreshToken', action.json.refresh_token);
        return action.json.id_token;
      }
    } else if (action.type === `${FETCH_TOKEN}_FAILURE`) {
      logout();
    }
    if (action.type === UPDATE_TOKEN) {
      localstorage.set('idToken', action.token);
      return action.token;
    }
    return state;
  },
  refreshToken: (state = localstorage.get('refreshToken'), action) => {
    if (action.type === `${FETCH_TOKEN}_SUCCESS`) {
      localstorage.set('refreshToken', action.json.refresh_token);
      return action.json.refresh_token;
    }
    if (action.type === UPDATE_TOKEN) {
      if (action.refreshToken) {
        localstorage.set('refreshToken', action.refreshToken);
        return action.refreshToken;
      }
    }
    return state;
  },
});
