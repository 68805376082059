import client from '../client';

import {
  GET_BRAINTREE_TOKEN,
  GET_PRICES,
  SUBMIT_RESULTS_PAYMENT,
  GET_BILLING_HISTORY,
  GET_SALES_TAX,
  RESET_SALES_TAX,
  GET_PROMO_CODE,
  RESET_SUBMIT_RESULTS_PAYMENT,
} from './payment-action-constants';

export function getPrices() {
  return {
    type: GET_PRICES,
    promise: client.get('payment/prices'),
  };
}

export function getSalesTax(payload) {
  return {
    type: GET_SALES_TAX,
    promise: client.post(`payment/calculate-sales-tax`, payload),
    meta: { values: payload },
  };
}

export function resetSalesTax() {
  return {
    type: RESET_SALES_TAX,
  };
}

export function getBraintreeToken(email) {
  return {
    type: GET_BRAINTREE_TOKEN,
    promise: client.get('payment/customer-token'),
  };
}

export function submitResultsPayment(nonce, payload) {
  return {
    type: SUBMIT_RESULTS_PAYMENT,
    promise: client.post('payment/player-results', { nonce, ...payload }),
  };
}

export function resetResultsPayment() {
  return {
    type: RESET_SUBMIT_RESULTS_PAYMENT,
  };
}

export function getBillingHistory(page = 0, page_size = 1) {
  return {
    type: GET_BILLING_HISTORY,
    promise: client.get(`user/payments?page=${page}&page_size=${page_size}`),
  };
}

export function getPromoCode(code) {
  return {
    type: GET_PROMO_CODE,
    promise: client.get(`promo?code=${code}`),
  };
}
