import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LocalizedStrings from 'react-localization';
import * as dropin from 'braintree-web-drop-in';
import PromoCodeForm from './PromoCodeForm';
import { connect } from 'react-redux';
import Dinero from 'dinero.js';
import getTotal from './get-total';
import ValidationError from '../../forms/ValidationError';
import config from '../../../config';
import { triggerGTMEvent } from '../../utils/gtm';

const strings = new LocalizedStrings({
  en: {
    cancel: 'Cancel',
    price: 'Amount',
    salesTax: 'Sales Tax',
    purchaseFor: 'Purchase for {price}',
    promoDiscount: 'Promo Discount',
    updating: 'Updating',
    invoice: 'Invoice',
    total: 'Total',
    noPaymentMethodSelected: 'No payment method selected',
    paymentCantBeProcessed: `Payment can't be processed at this time. Please try again later.`,
  },
});

class PaymentInfo extends Component {
  static propTypes = {
    token: PropTypes.string,
    totalTax: PropTypes.object, // Dinero object
    price: PropTypes.object.isRequired, // Dinero object
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    formLoaded: false,
    submittingPaymentInfo: false,
    braintreeError: null,
    showPromoCodeForm: true,
  };

  instance = null;

  componentDidMount() {
    if (this.props.token) {
      this.createPaymentForm();
    }

    if (this.props.price) {
      triggerGTMEvent('viewed_transaction_amount');
    }

    if (this.props.promoCode && this.props.promoCode.get('valid')) {
      const discountAmount = Dinero({
        amount: this.props.promoCode.getIn(['order', 'total_discount_amount']),
        currency: 'USD',
      });

      if (discountAmount.getAmount() === 0) {
        this.setState({ showPromoCodeForm: false });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.token && this.props.token) {
      this.createPaymentForm();
    }
  }

  componentWillUnmount() {
    if (this.instance) {
      this.instance.teardown();
    }
  }

  getApplePayRequestObject() {
    const total = this.getTotal();
    const displayTotal = total.toFormat('0.00');

    return {
      total: {
        label: 'Ball Fitting Fee',
        amount: displayTotal,
      },
      countryCode: 'US',
      currencyCode: 'USD',
    };
  }

  createPaymentForm() {
    const paypalConfig = config.payPalEnabled && { paypal: { flow: 'vault' } };
    const applePayConfig = config.applePayEnabled && {
      applePay: {
        displayName: 'Ping Ballnamic',
        paymentRequest: this.getApplePayRequestObject(),
      },
    };

    dropin.create(
      {
        authorization: this.props.token,
        selector: '#braintree-container',
        paymentOptionPriority: ['card', 'paypal', 'applePay'],
        ...paypalConfig,
        ...applePayConfig,
        card: {
          overrides: {
            styles: {
              input: {
                padding: '0 8px',
              },
            },
          },
        },
      },
      (err, instance) => {
        if (instance) {
          this.instance = instance;
          this.setState({ formLoaded: true });
        } else {
          this.setState({ braintreeError: strings.paymentCantBeProcessed });
          console.error(err || 'Payment form instance could not be initialized');
        }

        let visaCardIcon = document.querySelector('div[data-braintree-id="visa-card-icon"]');
        if (visaCardIcon) {
          visaCardIcon.setAttribute('aria-label', 'Visa');
        }
        let masterCardCardIcon = document.querySelector('div[data-braintree-id="master-card-card-icon"]');
        if (masterCardCardIcon) {
          masterCardCardIcon.setAttribute('aria-label', 'MasterCard');
        }
        let unionPayCardCardIcon = document.querySelector('div[data-braintree-id="unionpay-card-icon"]');
        if (unionPayCardCardIcon) {
          unionPayCardCardIcon.setAttribute('aria-label', 'Union Pay');
        }
        let amexCardIcon = document.querySelector('div[data-braintree-id="american-express-card-icon"]');
        if (amexCardIcon) {
          amexCardIcon.setAttribute('aria-label', 'American Express');
        }
        let jcbCardIcon = document.querySelector('div[data-braintree-id="jcb-card-icon"]');
        if (jcbCardIcon) {
          jcbCardIcon.setAttribute('aria-label', 'JCB');
        }
        let dinersClubCardIcon = document.querySelector('div[data-braintree-id="diners-club-card-icon"]');
        if (dinersClubCardIcon) {
          dinersClubCardIcon.setAttribute('aria-label', 'Diners Club');
        }
        let discoverCardIcon = document.querySelector('div[data-braintree-id="discover-card-icon"]');
        if (discoverCardIcon) {
          discoverCardIcon.setAttribute('aria-label', 'Discover');
        }
        let maestroCardIcon = document.querySelector('div[data-braintree-id="maestro-card-icon"]');
        if (maestroCardIcon) {
          maestroCardIcon.setAttribute('aria-label', 'Maestro Card');
        }
      }
    );
  }
  // This call has currently been moved to:
  // src/fitting/Questionnaire/Results/index.js
  // May use this late
  segmentDrop(){
    const {price, totalTax, promoCode} = this.props;
    if(promoCode && promoCode.get('order')){
     var pennies = promoCode.get('order').get('discount_amount') || promoCode.get('order').get('total_discount_amount') || null;
     var dollars = pennies / 100;
     var change = pennies % 100;
     var changes = change < 10 ? '0' + change : change.toString();
     var discountPost = dollars.toString() + '.' + changes.toString();
     var subtotal = (parseFloat(price.toFormat('0.00')) - parseFloat(discountPost)).toFixed(2);
      // Data layer event
     window.gtag('event', 'Order Completed', {
      fitting_id: null,
      order_id: null,
      total: this.getTotal().toFormat('0.00'),
      subtotal: price.toFormat('0.00'),
      tax: totalTax.toFormat('0.00'),
      discount: discountPost,
      coupon: promoCode.get('code'),
      products:
      {
        price: price.toFormat('0.00'),
        brand: "Balnamic",
        name: "Ballnamic Player Fitting",
        quantity: 1,
        discount: discountPost,
        coupon: promoCode.get('code')
      } 
      });
    
      window.analytics.track("Order Completed", {
        fitting_id: null,
        order_id: null,
        total: this.getTotal().toFormat('0.00'),
        subtotal: subtotal,
        tax: totalTax.toFormat('0.00'),
        discount: discountPost,
        coupon: promoCode.get('code'),
        products:
        {
          price: price.toFormat('0.00'),
          brand: 'Ballnamic',
          name: "Ballnamic Player Fitting",
          quantity: 1,
          discount: discountPost,
          coupon: promoCode.get('code')
        } 
      });
    }
    else{
      window.gtag('event', 'Order Completed', {
        fitting_id: null,
        order_id: null,
        total: this.getTotal().toFormat('0.00'),
        subtotal: price.toFormat('0.00'),
        tax: totalTax.toFormat('0.00'),
        products:
        {
          price: price.toFormat('0.00'),
          brand: 'Ballnamic',
          name: "Ballnamic Player Fitting",
          quantity: 1
        }
      });
      window.analytics.track("Order Completed", {
        fitting_id: null,
        order_id: null,
        total: this.getTotal().toFormat('0.00'),
        subtotal: price.toFormat('0.00'),
        tax: totalTax.toFormat('0.00'),
        products:
        {
          price: price.toFormat('0.00'),
          brand: 'Ballnamic',
          name: "Ballnamic Player Fitting",
          quantity: 1
        } 
      });
    }
  }

  handleSubmit = e => {
    const { onSubmit } = this.props;
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    this.setState({ submittingPaymentInfo: true, braintreeError: null });

    if (!this.instance) {
      console.error('Payment form instance not initialized yet');
      this.setState({ submittingPaymentInfo: false });
    } else {
      const total = this.getTotal();
      if (total.getAmount() === 0) {
        this.segmentDrop();
        return onSubmit('dummy');
      } else {
        this.instance.requestPaymentMethod((err, payload) => {
          if (payload) {
            this.segmentDrop();
            return onSubmit(payload.nonce);
          } else {
            if (err) {
              let message = err.message;
              if (message === 'No payment method is available.') {
                message = strings.noPaymentMethodSelected;
              }
              this.setState({ braintreeError: message });
            }
            this.setState({ submittingPaymentInfo: false });
          }
        });
      }
    }
  };

  getTotal() {
    const { totalTax, price, promoCode } = this.props;
    
    return getTotal(price, totalTax, promoCode);
  }

  renderDiscount() {
    const { promoCode } = this.props;

    if (!promoCode || !promoCode.get('valid')) {
      return null;
    }

    const discountAmount = Dinero({
      amount: promoCode.getIn(['order', 'total_discount_amount']),
      currency: 'USD',
    });

    return (
      <>
        {discountAmount.getAmount() !== 0 && (
          <tr>
            <td>
              <div className="promo-discount__wrapper">
                <div id="promo-discount-amount-label">{strings.promoDiscount}</div>
                <div className="promo-code">{promoCode.get('code')}</div>
              </div>
            </td>
            <td aria-labelledby="promo-discount-amount-label">-{discountAmount.toFormat('$0,0.00')}</td>
          </tr>
        )}
      </>
    );
  }

  renderInvoice() {
    const { totalTax, price } = this.props;

    return (
      <table className="invoice-table" aria-label={strings.invoice}>
        <thead>
          <tr>
            <th></th>
            <th>{strings.price}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ballnamic</td>
            <td>{price.toFormat('$0,0.00')}</td>
          </tr>
          {this.renderDiscount()}
          <tr>
            <td id="tax-amount-label">{strings.salesTax}</td>
            <td aria-labelledby="tax-amount-label">{totalTax ? totalTax.toFormat('$0,0.00') : strings.updating}</td>
          </tr>
          <tr>
            <td id="total-label">{strings.total}</td>
            <td aria-labelledby="total-label">{totalTax ? this.getTotal().toFormat('$0,0.00') : strings.updating}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const { onCancel, totalTax } = this.props;
    const isSubmitting = this.state.submittingPaymentInfo || this.props.submittingPayment;

    return (
      <div>
        {this.renderInvoice()}
        {this.state.showPromoCodeForm && <PromoCodeForm />}
        <form onSubmit={this.handleSubmit}>
          <div id="braintree-container"></div>
          {this.state.braintreeError && <ValidationError>{this.state.braintreeError}</ValidationError>}
          {this.state.formLoaded ? (
            <div className="payment-form--button__wrapper">
              <button type="button" className="button button--color--white" onClick={onCancel}>
                {strings.cancel}
              </button>
              <button className="button button--color--primary" type="submit" disabled={isSubmitting || !totalTax}>
                {totalTax
                  ? strings.formatString(strings.purchaseFor, { price: this.getTotal().toFormat('$0,0.00') })
                  : strings.updating}
              </button>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

export default connect(state => ({
  promoCode: state.payment.promoCode,
}))(PaymentInfo);
