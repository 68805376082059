import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { getArticles } from '../lib/contentful/contentful-actions';
import LoadingIndicator from '../common/components/LoadingIndicator';
import ArticleItem from './ArticleItem';
import Pager from '../common/components/Pager';
import querystring from 'query-string';
import SearchIcon from '../common/components/icons/Search';
import { Helmet } from 'react-helmet';
import LocalizedStrings from 'react-localization';

const PAGE_SIZE = 10;

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'Library',
  },
});

class Library extends Component {
  componentDidMount() {
    window.analytics.page("Library");
    const { dispatch, location } = this.props;

    const queryParams = querystring.parse(location.search);

    dispatch(getArticles(queryParams.query || null));
  }

  componentDidUpdate(prevProps) {
    const { dispatch, location } = this.props;

    const prevQueryParams = querystring.parse(prevProps.location.search);
    const queryParams = querystring.parse(location.search);

    if (prevQueryParams.query !== queryParams.query) {
      dispatch(getArticles(queryParams.query || null));
    }
  }

  getPageItems() {
    const { articles, location } = this.props;

    const queryParams = querystring.parse(location.search);
    const offset = PAGE_SIZE * (queryParams.page || 0);

    return articles.get('items').slice(offset, offset + PAGE_SIZE);
  }

  render() {
    const { articles, loaded, location, history } = this.props;

    const queryParams = querystring.parse(location.search);
    const items = loaded && this.getPageItems();

    return (
      <div className="container library">
        <Helmet>
          <title>{`${strings.page} - ${strings.title}`}</title>
        </Helmet>
        {!loaded ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="library--search-field">
              <Formik
                initialValues={{ query: queryParams.query || '' }}
                onSubmit={values => {
                  if (values.query) {
                    history.push(`/library?query=${values.query}`);
                  } else {
                    history.push('/library');
                  }
                }}
              >
                <Form>
                  <Field type="text" name="query" placeholder="Search for anything..." />
                  <div className="search-icon">
                    <SearchIcon />
                  </div>
                </Form>
              </Formik>
            </div>
            <div className="article-item--list">
              {items.size ? (
                items.map(article => <ArticleItem key={article.getIn(['sys', 'id'])} article={article.get('fields')} />)
              ) : (
                <div>No results found.</div>
              )}
            </div>
            {articles.get('total') > PAGE_SIZE ? (
              <Pager totalItems={articles.get('total')} pageSize={PAGE_SIZE} />
            ) : null}
          </>
        )}
      </div>
    );
  }
}

export default connect(state => ({
  loaded: state.contentful.getArticles.get('loaded'),
  articles: state.contentful.articles,
  token: state.auth.token,
  loggedInUser: state.auth.loggedInUser,
  fetchingLoggedInUser: state.auth.fetchLoggedInUser.get('loading'),
}))(Library);
