import React, { Component } from 'react';
import { withFormik, Form, Field, ErrorMessage } from 'formik';
import LocalizedStrings from 'react-localization';
import * as Yup from 'yup';
import FieldWithLabelAndValidation from '../common/forms/FieldWithLabelAndValidation';
import { register, fetchLoggedInUser } from './auth-actions';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';
import ValidationError from '../common/forms/ValidationError';
import Logo from '../common/components/logo/Logo';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FatCheck from '../common/components/icons/FatCheck';




const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'Registration',
    register: 'Join the Ballnamic Community',
    alreadyRegistered: 'Already have an account? {0}',
    login: 'Log in',
    createAccount: 'Start My Fitting',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    password: 'Password',
    fieldIsRequired: 'Field is required',
    fieldMustBeAValidEmailAddress: 'Field must be a valid email address',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    mustAgreeTerms: 'Agreeing to the Terms of Use and Privacy Policy is required',
    firstNamePlaceholder: 'Enter First Name',
    lastNamePlaceholder: 'Enter Last Name',
    emailPlaceholder: 'Enter Email Address',
    passwordPlaceholder: 'Enter Password',
    usOnlyDisclaimer: 'Ballnamic is currently available in the U.S. only',
    bulletpoint1: 'Personalized ball fitting driven by unbiased testing',
    bulletpoint2: 'Exclusive promos & new ball updates directly to your inbox',
    bulletpoint3: 'Get ahead of your playing partners with a fitted golf ball',
    agreeToTermsAndPolicy: 'I agree to the {0} and have read the {1}',
  },
});

class Registration extends Component {
  componentDidMount(){
    window.analytics.page("Registration");
  }
  componentDidUpdate(prevProps) {

    const { registered, loggedInUser, history, dispatch } = this.props;
    if (!prevProps.registered && registered) {
      dispatch(fetchLoggedInUser());
    }

    if (!prevProps.loggedInUser && loggedInUser) {
      if (localStorage.getItem('giftBallFitting') === 'true') {
        localStorage.removeItem('giftBallFitting');
        history.push('/gift');
      } else {
        history.push('/my-fitting');
      }
    }
  }

  renderError() {
    const { error } = this.props;

    if (!error) {
      return null;
    }

    return <ValidationError>{error.get('message')}</ValidationError>;
  }

  render() {
    const { submitting, loading } = this.props;

    return (
      <div className="registration--page">
        <Helmet>
          <title>{`${strings.page} - ${strings.title}`}</title>
        </Helmet>
        <div className="header">
          <div className="container">
            <div className="header__inner">
              <Logo />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="register-form__wrapper">
            <div className="register-form--header">
              <h1>{strings.register}</h1>

              <div className="register-form--bulletpoint">
                <span className="check">
                  <FatCheck />
                </span>
                <p>{strings.bulletpoint1}</p>
              </div>
              <div className="register-form--bulletpoint">
                <span className="check">
                  <FatCheck />
                </span>
                <p>{strings.bulletpoint2}</p>
              </div>
              <div className="register-form--bulletpoint">
                <span className="check">
                  <FatCheck />
                </span>
                <p>{strings.bulletpoint3}</p>
              </div>
            </div>

            {this.renderError()}
            <Form>
              <div className="register-form--name">
                <FieldWithLabelAndValidation
                  className="first-name"
                  label={strings.firstName}
                  fieldProps={{
                    name: 'first_name',
                    type: 'text',
                    placeholder: strings.firstNamePlaceholder,
                  }}
                />
                <FieldWithLabelAndValidation
                  className="last-name"
                  label={strings.lastName}
                  fieldProps={{
                    name: 'last_name',
                    type: 'text',
                    placeholder: strings.lastNamePlaceholder,
                  }}
                />
              </div>
              <FieldWithLabelAndValidation
                label={strings.email}
                fieldProps={{
                  name: 'email',
                  type: 'email',
                  placeholder: strings.emailPlaceholder,
                }}
              />
              <FieldWithLabelAndValidation
                label={strings.password}
                fieldProps={{
                  name: 'password',
                  type: 'password',
                  placeholder: strings.passwordPlaceholder,
                }}
              />
              <label className="checkbox">
                <Field type="checkbox" name="agreedToTermsAndPolicy" />
                <span>
                  {strings.formatString(
                    strings.agreeToTermsAndPolicy,
                    <Link target="_blank" to="/terms-of-use">
                      {strings.termsOfUse}
                    </Link>,
                    <Link target="_blank" to="/privacy">
                      {strings.privacyPolicy}
                    </Link>
                  )}
                </span>
                <ErrorMessage name="agreedToTermsAndPolicy" component={ValidationError} />
              </label>

              <div className="registration--page--button__wrapper">
                <button className="button button--color--primary" type="submit" disabled={submitting || loading}>
                  {strings.createAccount}
                </button>
                <div>
                  {strings.formatString(
                    strings.alreadyRegistered,
                    <Link to="/login" aria-label={strings.alreadyRegistered}>
                      {strings.login}
                    </Link>
                  )}
                </div>
              </div>
            </Form>
            <p className="us-only-disclaimer-text">{strings.usOnlyDisclaimer}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loading: state.auth.register.get('loading') || state.auth.fetchLoggedInUser.get('loading'),
    registered: state.auth.register.get('loaded'),
    error: state.auth.register.get('error'),
    loggedInUser: state.auth.loggedInUser,
  })),
  withFormik({
    mapPropsToValues: props => ({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      agreedToTermsAndPolicy: false,
    }),
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(strings.fieldIsRequired),
      last_name: Yup.string().required(strings.fieldIsRequired),
      email: Yup.string()
        .email(strings.fieldMustBeAValidEmailAddress)
        .required(strings.fieldIsRequired),
      password: Yup.string()
        .min(8)
        .required(strings.fieldIsRequired),
      agreedToTermsAndPolicy: Yup.boolean()
        .equals([true], strings.mustAgreeTerms)
        .required(),
    }),
    handleSubmit: (values, { props }) => {
      window.analytics.track("Account Created",{
        first_name: values.first_name,
        last_name: values.last_name,
        email:  values.email
      });
      window.gtag('event', 'Account Created', {
        first_name: values.first_name,
        last_name: values.last_name,
        email:  values.email
      });
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
      };

      return props.dispatch(register(payload));
    },
  })
)(Registration);
