import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Logo from '../common/components/logo/Logo';
import MenuIcon from '../common/components/icons/Menu';
import ClearIcon from '../common/components/icons/Clear';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    gift: "Gift a Ball Fitting",
    login: 'Login',
    registration: 'Register',
    library: 'Library',
    startMyFitting: 'Start My Fitting',
    landingPage: 'Landing Page',
    toggleSideBar: 'Toggle Side Bar',
    faq: 'FAQ',
  },
});

const activeStyle = {
  borderBottom: '4px solid #5057FF',
};

class LandingPageHeader extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
  };

  state = {
    showKebabMenu: false,
    showMobileKebabMenu: false,
    showSideBar: false,
  };

  toggleSidebar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  };

  renderMobileNav() {
    const { isLoggedIn } = this.props;

    return (
      <div className="mobile-nav">
        <div className="mobile-nav__flex-full">
          <button type="button" onClick={this.toggleSidebar} aria-label={strings.toggleSideBar}>
            <MenuIcon fill="#616A70" />
          </button>
        </div>
        <div className="mobile-nav__logo">
          <Logo fill="#5057FF" width={111.67} height={32} />
        </div>
        <div className="mobile-nav__flex-full">
          {isLoggedIn ? (
            <Link className="button button--color--primary button--theme--outline login-button" to="/my-fitting">
              {strings.startMyFitting}
            </Link>
          ) : (
            <Link className="button button--color--primary button--theme--outline login-button" to="/registration">
              {strings.registration}
            </Link>
          )}
        </div>
      </div>
    );
  }

  renderSideBar() {
    const { showSideBar } = this.state;

    const sidebarClasses = {
      sidebar: true,
      'sidebar--open': showSideBar,
    };

    const sidebarHeaderClasses = {
      'sidebar--header': true,
      'sidebar--header--open': showSideBar,
    };

    const mobileOverlayClasses = {
      'mobile-overlay': true,
      'mobile-overlay--visible': showSideBar,
    };
    const sidebarMenuClasses = {
      'sidebar-menu': true,
      'sidebar-menu--visible': showSideBar,
    };

    return (
      <Fragment>
        <div className={classNames(sidebarClasses)}>
          <div className={classNames(sidebarHeaderClasses)}>
            <button type="button" onClick={this.toggleSidebar} aria-label={strings.toggleSideBar}>
              <ClearIcon fill="#616A70" />
            </button>
          </div>
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink className="button button-gift-mobile user-nav__links--link" to="/gift" activeStyle={activeStyle}>
              {strings.gift}
            </NavLink>
          </div>
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink className="user-nav__links--link" to="/library" activeStyle={activeStyle}>
              {strings.library}
            </NavLink>
          </div>
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink className="user-nav__links--link" to="/faq" activeStyle={activeStyle}>
              {strings.faq}
            </NavLink>
          </div>
          {!this.props.isLoggedIn && (
            <div className={classNames(sidebarMenuClasses)}>
              <NavLink className="user-nav__links--link" to="/login" activeStyle={activeStyle}>
                {strings.login}
              </NavLink>
            </div>
          )}
          {!this.props.isLoggedIn && (
            <div className={classNames(sidebarMenuClasses)}>
              <NavLink className="user-nav__links--link" to="/registration" activeStyle={activeStyle}>
                {strings.registration}
              </NavLink>
            </div>
          )}
        </div>
        <div className={classNames(mobileOverlayClasses)} onClick={this.toggleSidebar} />
      </Fragment>
    );
  }

  render() {
    const { isLoggedIn } = this.props;

    return (
      <div className="landing-page-header">
        {this.renderMobileNav()}
        {this.renderSideBar()}
        <div className="container">
          <header className="header">
            <div className="header__logo-wrapper">
              <Link to="/" className="logo" aria-label={strings.landingPage}>
                <Logo width={172} height={48} />
              </Link>
            </div>
            <nav className="nav" role="navigation" aria-label="Navigation menu">
              <div className="nav-links">
                <div>
                  <Link className="button button--color--primary button--theme--outline" to="/library">
                    {strings.library}
                  </Link>
                </div>
                <div>
                  <Link className="button button--color--primary button--theme--outline" to="/faq">
                    {strings.faq}
                  </Link>
                </div>
                <div style={{ width: 1, height: 48, backgroundColor: 'rgba(80, 87, 255, 0.5)' }} />
                <div className='gift-button-wrapper'>
                  <Link className="button button--color--primary  button--theme--outline" to="/gift">
                    {strings.gift}
                  </Link>
                </div>
                <div>
                  {isLoggedIn ? (
                    <Link className="button button--color--primary" to="/my-fitting">
                      {strings.startMyFitting}
                    </Link>
                  ) : (
                    <Link className="button button--color--primary button--theme--outline" to="/login">
                      {strings.login}
                    </Link>
                  )}
                </div>
                {!isLoggedIn && (
                  <div>
                    <Link className="button button--color--primary" to="/registration">
                      {strings.registration}
                    </Link>
                  </div>
                )}
              </div>
            </nav>
          </header>
        </div>
      </div>
    );
  }
}

export default LandingPageHeader;